import React, { useEffect, useMemo, useState } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { useLocation } from '@reach/router';
import getLinkConfig, { LinkConfigType } from '@helpers/get-link-config';
import Section from '@components/section';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import { Container, LinkButton } from './confirmation-message.styled';

interface ConfirmationMessageItem {
  option: string;
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link_label?: string;
  link?: {
    url?: string;
  };
}

interface ConfirmationMessageProps {
  items: ConfirmationMessageItem[];
}

const defaultProps: ConfirmationMessageProps = {
  items: [
    {
      option: 'default',
      title: {
        text: 'Default Title',
      },
      description: {
        richText: [],
      },
      link_label: 'Request a Demo',
      link: {
        url: '',
      },
    },
  ],
};

const ConfirmationMessage = ({ items }: ConfirmationMessageProps = defaultProps): JSX.Element => {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get('type') || '';
  const [selectedItem, setSelectedItem] = useState<ConfirmationMessageItem>();
  const [selectedLinkConfig, setSelectedLinkConfig] = useState<LinkConfigType>();
  const origin = new URLSearchParams(location.search).get('utm_campaign') || '';
  const destination = origin || selectedLinkConfig?.url || '/';

  const memoizedSelectedItem = useMemo(() => {
    return type ? items.find((item) => item.option === type) : items[0];
  }, [type, items]);

  const memoizedSelectedLinkConfig = useMemo(() => {
    return getLinkConfig(memoizedSelectedItem?.link);
  }, [memoizedSelectedItem]);

  useEffect(() => {
    setSelectedItem(memoizedSelectedItem);
    setSelectedLinkConfig(memoizedSelectedLinkConfig);
  }, [memoizedSelectedItem, memoizedSelectedLinkConfig]);

  return (
    <>
      <Section backgroundColor="white">
        <Wrapper>
          <Container>
            <Text
              tag="h1"
              fontColor="secondary"
              type="title"
              fontSize="heading2"
              stringText={selectedItem?.title?.text}
            />
            <Text
              type="description"
              richText={selectedItem?.description?.richText}
              fontSize="large"
            />
            {selectedLinkConfig?.url && (
              <LinkButton
                type="button"
                url={destination}
                title={selectedItem?.link_label || 'Request a Demo'}
                isExternal={selectedLinkConfig?.isExternal}
                displayArrow={false}
              />
            )}
          </Container>
        </Wrapper>
      </Section>
    </>
  );
};

export default ConfirmationMessage;

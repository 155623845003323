import styled, { css } from 'styled-components';
import Image from '@components/image';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: stretch;
      max-width: 12.5rem;
    }
  `}
`;

export const LinkButton = styled.a`
  display: flex;
  gap: 0.74rem;
  height: auto;
  padding: 0.63rem 0.88rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-family: inherit;

  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.black};
    color: ${theme.colors.black};
  `}
`;

export const StyledImage = styled(Image)`
  width: 2rem;
  height: 2rem;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.fontSize.body.desktop};
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.extraSmall.desktop};
  `}
`;

import React, { useEffect, useState, useRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Section from '@components/section';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import LinkComponent from '@components/link';
import {
  CheckMark,
  XMark,
  ContentBlock,
  TableWrapper,
  ComparisonTable,
  HeaderList,
  ItemHeader,
  ComparisonBody,
  RowHeader,
  ComparisonRow,
  RowItem,
  RowHeaderWrapper,
  RowItemWrapper,
  ShadowWrapper,
  ItemHeaderWrapper,
} from './comparison.styled';

export interface RowTitlesProps {
  row_title?: string;
}

export interface ColumnProps {
  primary: {
    column_title: string;
  };
  items: {
    show_yes: boolean;
    show_no: boolean;
    row_description: {
      richText: RichTextBlock[];
    };
    button_text?: string;
    button_url?: {
      url: string;
    };
  }[];
}

interface ComparisonProps {
  tableContent: {
    document?: {
      data: {
        title: {
          text: string;
        };
        description: {
          richText: RichTextBlock[];
        };
        row_titles: RowTitlesProps[];
        body: ColumnProps[];
      };
    };
  };
}

const Comparison = ({ tableContent }: ComparisonProps): JSX.Element => {
  const tableRef = useRef<HTMLDivElement>(null);
  const rowHeaderRef = useRef<HTMLTableCellElement>(null);

  const [isXScrolled, setIsXScrolled] = useState<boolean>(false);
  const [isYScrolled, setIsYScrolled] = useState<boolean>(false);
  const [rowHeaderWidth, setRowHeaderWidth] = useState<number>(0);

  // check if the user has scrolled to the horizontal edge of the table
  const handleScroll = (event: Event) => {
    const target = event.target as HTMLDivElement;
    const { scrollLeft, scrollTop } = target;

    if (scrollLeft < 10) {
      setIsXScrolled(false);
    } else {
      setIsXScrolled(true);
    }

    if (scrollTop < 10) {
      setIsYScrolled(false);
    } else {
      setIsYScrolled(true);
    }
  };

  // get and set the width of the row header cell and update on resize to keep it in sync
  useEffect(() => {
    const rowHeader = rowHeaderRef.current;

    if (rowHeader) {
      setRowHeaderWidth(rowHeader.offsetWidth);
    }

    const handleResize = () => {
      if (rowHeader) {
        setRowHeaderWidth(rowHeader.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const table = tableRef.current;

    if (table) {
      table.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (table) {
        table.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Section backgroundColor="white">
      <Wrapper>
        <ContentBlock>
          <Text
            type="title"
            tag="h3"
            fontSize="heading3"
            stringText={tableContent.document?.data.title.text}
            margin="sm"
          />
          <Text
            type="subtitle"
            fontSize="subheading"
            richText={tableContent.document?.data.description.richText}
            margin="lg"
          />
        </ContentBlock>
        <ShadowWrapper
          $isXScrolled={isXScrolled}
          $isYScrolled={isYScrolled}
          $rowHeaderWidth={rowHeaderWidth}
        >
          <TableWrapper ref={tableRef}>
            <ComparisonTable>
              <HeaderList>
                <tr>
                  <ItemHeader scope="col" className="fixed" />
                  {tableContent.document?.data.body.map((column) => (
                    <ItemHeader scope="col" key={column.primary.column_title}>
                      <ItemHeaderWrapper>{column.primary.column_title}</ItemHeaderWrapper>
                    </ItemHeader>
                  ))}
                </tr>
              </HeaderList>
              <ComparisonBody>
                {tableContent.document?.data.row_titles.map((rowTitle, index) => (
                  <ComparisonRow>
                    <RowHeader scope="row" key={rowTitle.row_title}>
                      <RowHeaderWrapper>{rowTitle.row_title}</RowHeaderWrapper>
                    </RowHeader>
                    {tableContent.document?.data.body.map((column) => {
                      const rowDescription = column.items[index].row_description.richText;
                      const rowButtonText = column.items[index].button_text;
                      const rowButtonURL = column.items[index].button_url?.url;
                      return (
                        <RowItem>
                          <RowItemWrapper>
                            {column.items[index].show_yes && (
                              <>
                                <CheckMark />
                                Yes
                              </>
                            )}
                            {column.items[index].show_no && (
                              <>
                                <XMark />
                                No
                              </>
                            )}
                            {rowDescription && (
                              <Text
                                fontSize="small"
                                richText={column.items[index].row_description.richText}
                                margin={undefined}
                              />
                            )}
                            {rowButtonText && rowButtonURL && (
                              <LinkComponent
                                type="button"
                                url={rowButtonURL}
                                title={rowButtonText}
                                displayArrow={false}
                                isExternal
                                target="_self"
                              />
                            )}
                          </RowItemWrapper>
                        </RowItem>
                      );
                    })}
                  </ComparisonRow>
                ))}
              </ComparisonBody>
            </ComparisonTable>
          </TableWrapper>
        </ShadowWrapper>
      </Wrapper>
    </Section>
  );
};

export default Comparison;

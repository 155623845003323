import React, { useState, useRef } from 'react';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import ReCAPTCHA from 'react-google-recaptcha';
import InputGroup from '@components/input-group';
import {
  StyledForm,
  FormRow,
  FormInputs,
  TitleContainer,
  Fields,
  ConfirmationWrapper,
  SubmitContainer,
  StyledButton,
} from './cta-newsletter.styled';

export interface EnclosedNewsletterFormProps {
  title?: string;
  description?: RichTextBlock[];
  buttonLabel: string;
  confirmationTitle?: string;
  confirmationMessage?: RichTextBlock[];
  backgroundColor: keyof typeof defaultTheme.backgroundColors;
  formId?: string;
}

const CTANewsletter = ({
  buttonLabel,
  confirmationTitle,
  confirmationMessage,
  title,
  description,
  formId,
  backgroundColor,
}: EnclosedNewsletterFormProps): JSX.Element => {
  // captcha
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [disabledState, setDisabledState] = React.useState(true);
  const [captchaResponse, stCaptchaResponse] = useState('');
  const sitekey = '6Le4oVoqAAAAAID4h2TaX9kEyN09BiFwN5PLGDYT';

  // hubspot form
  const portalId = '39489460';
  const form: string = formId || 'de32ea1f-d85b-426c-a3e2-e9786612e88c';
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${form}`;

  // get current page info
  const currentURL = typeof window !== 'undefined' ? window.location.href : 'Unknown URL';
  const pageTitle = typeof document !== 'undefined' ? document.title : 'Unknown title';
  const cleanPageTitle = pageTitle.replace(/TripShot \| /i, '');

  // states
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const setExpired = () => {
    stCaptchaResponse('');
    setDisabledState(true);
  };

  const setCaptchaValue = () => {
    const recaptchaValue = JSON.stringify(new Date().getTime());
    stCaptchaResponse(recaptchaValue);
    setDisabledState(false);
  };

  const submitToHubspot = () => {
    return new Promise((resolve, reject) => {
      const date = Date.now();
      const data = {
        submittedAt: date,
        fields: [
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'company',
            value: companyName,
          },
        ],
        context: {
          pageUri: currentURL,
          pageName: cleanPageTitle,
        },
      };
      const final_data = JSON.stringify(data);
      const xhr = new XMLHttpRequest();

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else if (xhr.status === 400) {
            reject(new Error(xhr.statusText));
          } else if (xhr.status === 403) {
            reject(new Error(xhr.statusText));
          } else {
            reject(new Error(xhr.statusText));
          }
        }
      };
      xhr.send(final_data);
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitted(true);
    if (!firstName || !lastName || !companyName || !email || disabledState) {
      e.preventDefault();
    } else {
      e.preventDefault();
      submitToHubspot()
        .then(() => setDisplayConfirmation(true))
        .catch((err) => console.error(err));
    }
  };

  return (
    <Section backgroundColor={backgroundColor}>
      <Wrapper>
        <StyledForm onSubmit={handleSubmit} id={`cta-form-${form}`}>
          <TitleContainer>
            {title && (
              <Text
                tag="h3"
                fontColor="primary"
                type="title"
                fontSize="heading3"
                stringText={title}
                margin="none"
              />
            )}
            {description && (
              <Text
                type="subheading"
                fontSize="heading6"
                richText={description}
                fontColor="primary"
                margin="none"
              />
            )}
          </TitleContainer>
          <FormInputs>
            <Fields>
              <input
                type="hidden"
                name="captcha_settings"
                value={`{"keyname":"Cometv2","fallback":"true","orgId":"00Df4000001eE2k","ts":"${captchaResponse}"}`}
              />
              <FormRow>
                <InputGroup
                  isInvalid={isSubmitted && firstName === ''}
                  disabled={displayConfirmation}
                  placeholder="Your First Name"
                  name="firstname"
                  label="First Name"
                  value={firstName}
                  helpText="Please enter a first name"
                  onChange={setFirstName}
                />
                <InputGroup
                  isInvalid={isSubmitted && lastName === ''}
                  disabled={displayConfirmation}
                  placeholder="Your Last Name"
                  name="lastname"
                  label="Last Name"
                  value={lastName}
                  helpText="Please enter a last name"
                  onChange={setLastName}
                />
              </FormRow>
              <FormRow>
                <InputGroup
                  isInvalid={isSubmitted && email === ''}
                  disabled={displayConfirmation}
                  placeholder="Your Email Address"
                  name="email"
                  label="Email"
                  value={email}
                  helpText="Please enter a email address"
                  onChange={setEmail}
                />
                <InputGroup
                  isInvalid={isSubmitted && companyName === ''}
                  disabled={displayConfirmation}
                  placeholder="Your Company Name"
                  name="company"
                  label="Company"
                  value={companyName}
                  helpText="Please enter a last name"
                  onChange={setCompanyName}
                />
              </FormRow>
            </Fields>
            <SubmitContainer>
              {/* @ts-expect-error Server Component */}
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={sitekey}
                onChange={setCaptchaValue}
                onExpired={setExpired}
              />
              <StyledButton
                type="submit"
                label={buttonLabel}
                variant="dark"
                disabled={disabledState || displayConfirmation}
              />
            </SubmitContainer>
          </FormInputs>
        </StyledForm>
        {displayConfirmation && (
          <ConfirmationWrapper>
            <Text
              type="title"
              tag="h1"
              fontSize="heading3"
              fontColor="primary"
              stringText={confirmationTitle}
            />
            <Text
              type="description"
              fontColor="primary"
              richText={confirmationMessage}
              fontSize="large"
            />
          </ConfirmationWrapper>
        )}
      </Wrapper>
    </Section>
  );
};

export default CTANewsletter;

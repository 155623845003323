interface LinkType {
  url?: string;
  uid?: string;
}

export interface LinkConfigType {
  url: string;
  isExternal: boolean;
}

const getLinkConfig = (link?: LinkType): LinkConfigType => ({
  url: link?.uid ? `/${link?.uid}` : link?.url || '',
  isExternal: !!link?.url,
});

export default getLinkConfig;

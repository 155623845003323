import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import { Link } from 'gatsby';
import { underlineTransition } from '@components/link/link.styled';

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    ${generateScales('margin-bottom', '2rem', '4rem', theme)}
    max-width: 40rem;
    width: 100%;
    text-align: center;
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('margin-bottom', '3rem', '6rem', theme)}
    position: relative;
  `}
`;

export const AccordionItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0.5rem;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #e699ff;

  ${({ theme }) => css`
    color: ${theme.colors.primary};
    ${generateScales(
      'font-size',
      theme.fontSize.subheading.mobile,
      theme.fontSize.subheading.desktop,
      theme
    )}

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
    }
  `}
`;

export const Label = styled(Text)`
  font-weight: 500;
  margin: 0;
  width: fit-content;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const Container = styled.ul`
  width: 100%;
  align-items: center;
  border-top: 1px solid #e699ff;
`;

export const ListItem = styled.li`
  border-bottom: 1px solid #e699ff;
`;

export const TextAndIcon = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
`;

export const StyledText = styled(Label)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.additional};
    display: inline;
    ${underlineTransition};
    color: ${theme.colors.secondary};
  `}
`;

export const TextWrapper = styled.div`
  max-width: 22rem;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => css`
    text-decoration: none;
    color: ${theme.colors.secondary};
    font-family: ${theme.fontFamily.additional};

    :hover ${StyledText} {
      background-size: 100% 2px;
    }
  `}
`;

export const StyledTitleLink = styled(Link)`
  ${({ theme }) => css`
    ${underlineTransition}
    text-decoration: none;
    color: ${theme.colors.primary};
    font-family: ${theme.fontFamily.additional};

    :hover ${StyledText} {
      background-size: 100% 2px;
    }
  `}
`;

export const StyledItem = styled(AccordionItem)`
  cursor: default;
`;

import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import {
  ImageContainer,
  BioImage,
  Card,
  Meta,
  Title,
  CardLink,
  SubHeading,
} from './bio-card.styled';

export interface BioCardProps {
  photo?: ImageProps;
  name?: {
    text?: string;
  };
  job_title?: {
    richText: RichTextBlock[];
  };
  className?: string;
  isLinked?: boolean;
}

export const getBioPath = (BioName = ''): string => BioName.split(' ').join('-').toLowerCase();

const BioCard = ({
  photo,
  name,
  job_title,
  className,
  isLinked = true,
}: BioCardProps): JSX.Element => {
  if (!name?.text && !photo?.url && !job_title?.richText) {
    return <></>;
  }

  const Content = () => (
    <>
      {photo?.url && (
        <ImageContainer>
          <BioImage
            url={photo?.url}
            alt={photo?.alt}
            gatsbyImageData={photo.gatsbyImageData}
            objectFit="cover"
            className="bio-image"
          />
        </ImageContainer>
      )}
      <Meta>
        {name?.text && <Title type="title" fontSize="body" stringText={name.text} />}
        {job_title?.richText && (
          <SubHeading type="description" richText={job_title?.richText} fontSize="body" />
        )}
      </Meta>
    </>
  );

  return (
    <Card className={className}>
      {isLinked ? (
        <CardLink to={`/about/${getBioPath(name?.text)}`}>{Content()}</CardLink>
      ) : (
        Content()
      )}
    </Card>
  );
};

export default BioCard;

import Image from '@components/image';
import styled, { css, keyframes } from 'styled-components';
import Grid from '@components/grid';
import { generateScales } from '@helpers/generate-media-queries';
import Text from '@components/text';

export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 1.5rem;
    }
  `}
`;

const fadeIn = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform:translateX(4rem);
  }
  51% {
    opacity: 0;
    transform:translateX(-4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const StyledHead = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    gap: 4rem;
    margin: 0 auto 3rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 2rem;
      margin: 0 auto 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 2rem;
    }
  `}
`;

export const HeadContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 32rem;
    text-align: left;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center;
      align-self: center;
      row-gap: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    padding: 0.375rem;
    display: flex;
    width: fit-content;
    gap: 0.25rem;
    border: 1px solid ${theme.colors.muted};
    border-radius: 4rem;
    margin-top: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin-top: 0;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-width: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0;
    }
  `}
`;

export const StyledButton = styled.button<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    padding: 0 1.5rem;
    height: 3rem;
    border: none;
    outline: none;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)};
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    line-height: 1.25;
    cursor: pointer;

    background-color: ${isActive ? theme.colors.primary : theme.colors.transparent};
    color: ${isActive ? theme.colors.white : theme.colors.primary};
    border-radius: ${theme.borderRadius.large};

    &:hover {
      background-color: ${isActive ? theme.colors.primary : theme.colors.lightGreen};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 2.75rem;
      width: 100%;
      padding: 0 1.5rem;
    }
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
      padding: 0 1.25rem;
    }
  `}
`;

export const ImageContainer = styled.div<{ animate: boolean }>`
  ${({ animate, theme }) => css`
    width: 100%;
    max-width: 32rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }

    ${animate &&
    css`
      animation: 0.5s ${fadeIn} ease-in-out;
    `}
  `}
`;

export const Block = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 0.75rem;
    flex-direction: row;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0.75rem;
    }
  `}
`;

export const BlockDescription = styled(Text)`
  font-size: 1rem;
`;

export const TextBlock = styled.div`
  /* margin: 0.5rem 0 0; */
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    width: 100%;
    height: 18rem;

    img {
      object-position: 1rem center;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: 15rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 12rem;

      img {
        object-position: center center;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 12rem;

      img {
        object-position: 1rem center;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 10rem;

      img {
        object-position: 1rem center;
      }
    }
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 2.75rem;
      height: 2.75rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  `}
`;

export const TabContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1.25rem;

    ${ImageContainer} {
      display: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      align-items: center;
      ${ImageContainer} {
        display: flex;
        margin-top: 1rem;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
      ${ImageContainer} {
        display: flex;
        margin-top: 1rem;
      }
    }
  `}
`;

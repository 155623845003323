import React from 'react';

const VectorIcon = ({ className = '' }): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 1.5L10 10.5L1 1.5"
      stroke="#AA1EF5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VectorIcon;

import styled, { css } from 'styled-components';
import Image from '@components/image';

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 7rem 0;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 6rem 0;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 5rem 0;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 4rem 0;
    }
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      object-fit: cover;
      min-height: 5rem;
    }
  `}
`;

import React from 'react';
import Image, { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import { RichTextBlock } from 'prismic-reactjs';
import generateId from '@helpers/generate-id';

import {
  ImageContainer,
  Container,
  ContentContainer,
  Content,
  DescriptionContainer,
  Title,
  StyledTitle,
  StyledSubheading,
  StyledDescription,
  StyledImage,
  IconContainer,
} from './solutions.styled';

const SolutionIdGenerator = generateId();

export interface SolutionsProps {
  image?: ImageProps;
  generalTitle?: string;
  subheading?: RichTextBlock[];
  items: SolutionsPropsItem[];
}

export interface SolutionsPropsItem {
  icon?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
}

const Solutions = ({
  generalTitle,
  subheading = [],
  image,
  items,
}: SolutionsProps): JSX.Element => {
  const isSubheadingExisting: boolean = subheading?.length > 0 && subheading[0].text !== '';
  if (!generalTitle && !image && !isSubheadingExisting) {
    return <></>;
  }

  return (
    <Wrapper>
      {generalTitle && <Title type="title" tag="h2" stringText={generalTitle} />}
      <Container>
        <ImageContainer>
          {(image?.gatsbyImageData || image?.url) && (
            <Image url={image.url} alt={image.alt} gatsbyImageData={image.gatsbyImageData} />
          )}
        </ImageContainer>
        <ContentContainer>
          {isSubheadingExisting && <StyledSubheading type="subheading" richText={subheading} />}

          {items?.map(({ icon, title, description }) => {
            return (
              <Content key={SolutionIdGenerator.next().value}>
                {icon?.url && (
                  <IconContainer>
                    <StyledImage url={icon?.url} alt={icon?.alt} />
                  </IconContainer>
                )}
                <DescriptionContainer>
                  {title && (
                    <StyledTitle
                      tag="h3"
                      fontColor="dark"
                      type="title"
                      fontSize="heading5"
                      stringText={title.text}
                    />
                  )}
                  {description && (
                    <StyledDescription type="description" richText={description?.richText} />
                  )}
                </DescriptionContainer>
              </Content>
            );
          })}
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};

export default Solutions;

import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { RichTextBlock } from 'prismic-reactjs';
import Section from '@components/section';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { Cookies } from 'react-cookie-consent';
import Text from '@components/text';
import {
  LegalWrapper,
  CookieManagementContainer,
  StyledCheckbox,
  Toggle,
  ToggleLabel,
} from './cookie-management.styled';

const ANALYTICS_COOKIE_NAME = 'gatsby-gdpr-google-analytics';

interface CookieManagementProps {
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  toggleAllText?: string;
}

const CookieManagement = ({
  title,
  description,
  toggleAllText,
}: CookieManagementProps): JSX.Element => {
  const location = useLocation();
  const analyticsStatus = Cookies.get(ANALYTICS_COOKIE_NAME);
  const [acceptedTracking, setAcceptedTracking] = useState(analyticsStatus === 'true');

  const handleTrackingChange = () => {
    if (!acceptedTracking) {
      Cookies.set(ANALYTICS_COOKIE_NAME, 'true', { expires: 365 });
      initializeAndTrack(location);
      setAcceptedTracking(true);
    } else {
      Cookies.remove(ANALYTICS_COOKIE_NAME);
      setAcceptedTracking(false);
    }
  };

  return (
    <Section backgroundColor="white">
      <LegalWrapper>
        <CookieManagementContainer>
          <Text tag="h2" fontSize="heading5" type="title" stringText={title?.text} margin="xs" />
          <Text type="description" richText={description?.richText} />
          <ToggleLabel>
            {toggleAllText}
            <StyledCheckbox
              id="enableAllTracking"
              checked={acceptedTracking}
              onChange={handleTrackingChange}
            />
            <Toggle />
          </ToggleLabel>
        </CookieManagementContainer>
      </LegalWrapper>
    </Section>
  );
};

export default CookieManagement;

import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Section from '@components/section';

export const StyledSection = styled(Section)`
  width: 100%;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5rem;
    ${generateScales('column-gap', '3rem', '6rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column-reverse;
      align-items: flex-start;
      text-align: left;
      row-gap: 2rem;
    }
  `}
`;

export const BackgroundImageContainer = styled.div`
  ${({ theme }) => css`
    right: 0;
    width: 100%;
    max-width: 38rem;
    position: absolute;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;
    z-index: 1;

    max-width: 14rem;
    width: 100%;
    max-height: 24rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin: 0 auto;
      max-width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-height: 14rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-height: 12rem;
      img {
        object-position: center center;
      }
    }
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      order: 2;
    }
  `}
`;

export const MessageContainer = styled.div<{ align?: string }>`
  ${({ theme, align }) => css`
    width: 100%;
    max-width: 32rem;
    height: 100%;
    text-align: ${align};
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;

import React from 'react';
import styled, { css } from 'styled-components';
import Dropdown from '@components/dropdown';
import { useLocation } from '@reach/router';
import generateId from '@helpers/generate-id';
import { Link } from 'gatsby';
import { underlineTransition } from '@components/link/link.styled';

const idGenerator = generateId();
const linkIdGenerator = generateId();

interface LinkItemProps {
  layoutType: 'dark' | 'light';
}

interface StyledLinkProps {
  $isActive: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  ${({ theme, $isActive = false }) => css`
    ${underlineTransition}
    text-decoration: none;
    pointer-events: ${$isActive ? 'none' : 'auto'};
    font-family: ${theme.fontFamily.additional};
    font-size: 1.0625rem;
    font-weight: 500;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const StyledExternalLink = styled.a`
  ${({ theme }) => css`
    ${underlineTransition}
    text-decoration: none;
    font-family: ${theme.fontFamily.additional};
    font-size: 1.0625rem;
    font-weight: 500;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const StyledBorderedLink = styled(StyledLink)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    height: 2rem;
    border: ${theme.borderWidths.icons} solid ${theme.colors.accent};
    color: ${theme.colors.primary} !important;
    background-color: ${theme.colors.accent};
    border-radius: 1.25rem;
    font-weight: 500;
    font-family: ${theme.fontFamily.display};
    font-size: 0.9375rem;
    background-image: none;

    :hover {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      color: ${theme.colors.white} !important;
      text-decoration: none;
    }
  `}
`;

const LinkContainer = styled.div`
  position: relative;
  margin-right: 1.25rem;
`;

const PositionsNumber = styled.span`
  ${({ theme }) => css`
    display: block;
    position: absolute;
    left: 100%;
    top: 50%;
    text-align: center;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 100%;
    background-color: ${theme.colors.tertiary};
    font-size: 0.675rem;
    font-weight: 500;
    transform: translateY(-50%);
    margin-left: 0.25rem;
    color: ${theme.colors.white};
  `}
`;

export const LinkItem = styled.li<LinkItemProps>`
  ${({ theme, layoutType }) => css`
    ${StyledLink},
    ${StyledBorderedLink},
    ${StyledExternalLink} {
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
    }
  `}
`;

export interface NavbarLinksType {
  link_label: string;
  link_url: {
    url: string;
    target: string;
    document: {
      uid: string;
    };
  };
  dropdown_menu: {
    document: {
      uid: string;
      data: {
        title: {
          text: string;
        };
        body: {
          primary: {
            group_title: {
              text: string;
            };
          };
          items: {
            link: {
              url: string;
              document: {
                uid: string;
              };
            };
            link_label: string;
            description: string;
          }[];
        }[];
      };
    };
  };
}

const renderNavbarLinks = (
  navbarLinks: NavbarLinksType[],
  layoutType: 'dark' | 'light',
  positions: number
): JSX.Element[] =>
  navbarLinks.map((link) => {
    const location = useLocation();
    const { link_label, link_url, dropdown_menu } = link;
    const { document, url, target } = link_url;

    const linkType = (): JSX.Element => {
      // is internal link
      if (document !== null) {
        const to = document.uid === 'home' ? '/' : `/${document.uid}`;
        if (to === '/contact') {
          return (
            <StyledBorderedLink
              to={to}
              $isActive={location.pathname === to}
              id={`${linkIdGenerator.next().value}-navbar-link`}
            >
              {link_label}
            </StyledBorderedLink>
          );
        }
        if (to === '/careers') {
          return (
            <LinkContainer>
              <StyledLink
                to={to}
                $isActive={location.pathname === to}
                id={`${linkIdGenerator.next().value}-navbar-link`}
              >
                {link_label}
              </StyledLink>
              {positions > 0 && <PositionsNumber>{positions}</PositionsNumber>}
            </LinkContainer>
          );
        }
        return (
          <StyledLink
            to={to}
            $isActive={location.pathname === to}
            id={`${linkIdGenerator.next().value}-navbar-link`}
          >
            {link_label}
          </StyledLink>
        );
      }

      if (dropdown_menu.document) {
        return (
          <Dropdown
            title={dropdown_menu.document.data.title.text}
            data={dropdown_menu.document.data.body}
            layoutType={layoutType}
          />
        );
      }

      // external link
      return (
        <StyledExternalLink
          href={url}
          target={target !== null ? target : '_self'}
          id={`${linkIdGenerator.next().value}-navbar-link`}
        >
          {link_label}
        </StyledExternalLink>
      );
    };

    return (
      <LinkItem key={idGenerator.next().value} layoutType={layoutType}>
        {linkType()}
      </LinkItem>
    );
  });

export default renderNavbarLinks;

import { ImageProps } from '@components/image';
import React from 'react';
import { Container, LinkButton, StyledImage, Titles, Label } from './download-buttons.styled';

export interface ItemAppDownload {
  icon?: ImageProps;
  title?: {
    text: string;
  };
  link_label?: string;
  link?: {
    url?: string;
  };
}

interface DownloadButtonsProps {
  items?: ItemAppDownload[];
}

const DownloadButtons = ({ items }: DownloadButtonsProps): JSX.Element => (
  <Container>
    {items?.map(({ icon, title, link_label, link }) => (
      <LinkButton href={link?.url} key={link?.url}>
        <StyledImage url={icon?.url} alt={icon?.alt} />
        <Titles>
          <Label>{link_label}</Label>
          <div>{title?.text}</div>
        </Titles>
      </LinkButton>
    ))}
  </Container>
);

export default DownloadButtons;

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
exports.linkResolver = (doc) => {
  if (doc.uid === 'home') {
    return `/`;
  }

  if (doc.type === 'contact') {
    return `/${doc.uid}`;
  }

  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }

  if (doc.type === 'bio_page') {
    return `/about/${doc.uid}`;
  }

  if (doc.type === 'position') {
    return `/careers/${doc.uid}`;
  }

  if (doc.type === 'legal') {
    return `/${doc.uid}`;
  }

  return '/';
};

import React from 'react';
import { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import { RichTextBlock } from 'prismic-reactjs';
import Grid from '@components/grid';
import generateId from '@helpers/generate-id';

import {
  Container,
  ContentContainer,
  Content,
  DescriptionContainer,
  Title,
  StyledTitle,
  StyledSubheading,
  StyledDescription,
  StyledImage,
  IconContainer,
} from './spectrum.styled';

const SpectrumdGenerator = generateId();

export interface SpectrumProps {
  title?: string;
  subheading?: RichTextBlock[];
  itemsPerRow?: number;
  items: SpectrumPropsItem[];
}

export interface SpectrumPropsItem {
  image?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
}

const Spectrum = ({
  title,
  itemsPerRow = 2,
  subheading = [],
  items,
}: SpectrumProps): JSX.Element => {
  const isSubheadingExisting: boolean = subheading?.length > 0 && subheading[0].text !== '';
  if (!title && !isSubheadingExisting) {
    return <></>;
  }

  return (
    <Wrapper>
      <Container>
        {title && <Title type="title" tag="h2" stringText={title} />}
        <ContentContainer>
          {isSubheadingExisting && <StyledSubheading type="subheading" richText={subheading} />}
          <Grid itemsPerRow={itemsPerRow}>
            {items.map((item) => (
              <Content key={SpectrumdGenerator.next().value}>
                {item.image?.url && (
                  <IconContainer>
                    <StyledImage url={item.image?.url} alt={item.image?.alt} />
                  </IconContainer>
                )}
                <DescriptionContainer>
                  {title && (
                    <StyledTitle
                      tag="h3"
                      fontColor="dark"
                      type="title"
                      fontSize="heading5"
                      stringText={item.title?.text}
                    />
                  )}
                  {item.description && (
                    <StyledDescription type="description" richText={item.description?.richText} />
                  )}
                </DescriptionContainer>
              </Content>
            ))}
          </Grid>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};

export default Spectrum;

import styled, { DefaultTheme, css } from 'styled-components';
import ActiveQuoteBubble from '@images/active-quote-bubble.svg';
import QuoteBubble from '@images/quote-bubble.svg';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const ArrowContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-family: ${theme.fontFamily.text};
    color: ${theme.colors.secondary};

    ::before {
      transition: transform 0.25s ease-out, opacity 0.5s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${theme.fontSize.subheading.desktop};
      font-weight: normal;
      line-height: 1;
      height: 3rem;
      width: 3rem;
      border-radius: 1.5rem;
      background: ${theme.colors.secondary};
      color: ${theme.colors.white};
      opacity: 0;
    }
  `}
`;

const ArrowCSS = (theme: DefaultTheme) => css`
  position: absolute;
  z-index: 10;
  top: 0;
  transform: unset;
  height: 100%;
  width: calc(30% - 1rem);
  border-radius: 1rem;
  opacity: 0;
  transition: opacity 0.125s ease-in;

  :hover {
    opacity: 1;
  }

  ::before {
    display: none;
  }

  @media only screen and (max-width: ${theme.breakpoints.xxl}) {
    width: calc(25% - 1rem);
  }

  @media only screen and (max-width: ${theme.breakpoints.xl}) {
    width: calc(20% - 1rem);
  }

  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    width: calc(15% - 1rem);
  }
`;

export const NextArrow = styled.div`
  ${({ theme }) => css`
    &.slick-next {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 85%);
      :hover {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 85%);
        ${ArrowContent}::before {
          transform: translateX(0) scale(1);
          opacity: 1;
          transition: transform 0.25s ease-out, opacity 0.125s ease-out;
        }
      }
      right: 0;
      ${ArrowCSS(theme)}
    }
    ${ArrowContent} {
      justify-content: flex-end;
      padding-right: 1rem;

      ::before {
        content: '→';
        transform: translateX(-1rem) scale(0);
      }
    }
  `}
`;

export const PrevArrow = styled.div`
  ${({ theme }) => css`
    &.slick-prev {
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
      :hover {
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
        transition: transform 0.25s ease-out, opacity 0.125s ease-out;

        ${ArrowContent}::before {
          transform: translateX(0) scale(1);
          opacity: 1;
        }
      }
      left: 0;
      ${ArrowCSS(theme)}
    }
    ${ArrowContent} {
      padding-left: 1rem;

      ::before {
        content: '←';
        transform: translateX(1rem) scale(0);
      }
    }
  `}
`;

export const Container = styled.div`
  width: 100vw;
  margin-bottom: 4rem;

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      div:only-child {
        height: 100%;
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-center,
  .slick-slide {
    position: relative;

    ${({ theme }) => css`
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        left: 1rem;
        top: 3rem;
        display: block;
        height: 6.5rem;
        width: 6rem;
        background-size: 6rem 6.5rem;
        background-repeat: no-repeat;

        @media only screen and (max-width: ${theme.breakpoints.sm}) {
          top: 1.5rem;
          height: 3.5rem;
          width: 3rem;
          background-size: 3rem 3.5rem;
        }
      }
    `}
  }

  .slick-slide {
    &::before {
      background-image: url(${QuoteBubble});
    }
  }

  .slick-center {
    &::before {
      background-image: url(${ActiveQuoteBubble});
    }
  }
`;

export const SlickContainer = styled.div`
  padding: 0 1rem;
`;

export const QuoteText = styled(Text)`
  ${({ theme }) => css`
    position: relative;
    line-height: ${theme.lineHeights.lg};
    ${generateScales('margin-bottom', '1rem', '2rem', theme)}

    ::before {
      display: block;
      content: '\\201C';
      position: absolute;
      top: 0;
      right: 100%;
    }
  `}
`;

export const NameText = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('margin-bottom', '0.25rem', '0.5rem', theme)};
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 4rem 4rem 4rem 8.6rem;
    border-radius: 1rem;
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.normal};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 2rem 2rem 2rem 4rem;
      width: calc(100vw - 2rem);
    }
  `}
`;

export const Dots = styled.ul`
  ${({ theme }) => css`
    &.slick-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 1rem;
      row-gap: 0.5rem;
      top: calc(100% + 2rem);
      bottom: unset;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        padding: 0 4rem;
      }

      li {
        margin: 0;
        transition: transform 0.125s ease-out;
        transform-origin: center center;
        width: 1.25rem;
        height: 1.25rem;

        :not(.slick-active):hover {
          transform: scale(1.375);
        }

        button {
          display: flex;
          width: 1.25rem;
          height: 1.25rem;
          align-items: center;
          justify-content: center;
          padding: 0;
        }

        button:before {
          font-size: 1rem;
          line-height: 1rem;
          width: 1rem;
          height: 1rem;
          background-color: ${theme.colors.accent};
          border-radius: 0.5rem;
          opacity: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: '';
        }

        &.slick-active button:before {
          background-color: ${theme.colors.secondary};
        }
      }
    }
  `}
`;

import React from 'react';
import { StyledGrid } from './grid.styled';

interface GridProps {
  itemsPerRow?: number;
  children: React.ReactNode;
  className?: string;
}

const Grid = ({ itemsPerRow = 3, children, className = '' }: GridProps): JSX.Element => {
  const maxWrapperWidth = 58;
  const gridGap = 2;

  const itemWith = (maxWrapperWidth - gridGap * (itemsPerRow - 1)) / itemsPerRow;

  return (
    <StyledGrid className={className} itemsPerRow={itemsPerRow} itemWith={itemWith}>
      {children}
    </StyledGrid>
  );
};

export default Grid;

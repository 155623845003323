import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Section from '@components/section';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import { Container, Content, StyledText, LinkButton } from './call-to-action.styled';

interface CallToActionProps {
  title?: string;
  subheading?: RichTextBlock[];
  buttonLabel?: string;
  link?: string;
}

const CallToAction = ({
  title,
  subheading = [],
  buttonLabel,
  link,
}: CallToActionProps): JSX.Element => {
  const hasDescription: boolean = subheading?.length > 0 && subheading[0].text !== '';

  if (!hasDescription && !(buttonLabel && link)) {
    return <></>;
  }

  return (
    <Section backgroundColor="dark">
      <Wrapper widthSize="md">
        <Container>
          <Content>
            {title && <StyledText tag="h2" fontSize="heading5" type="title" stringText={title} />}
            {subheading && <Text type="subheading" richText={subheading} />}
            {link && (
              <LinkButton type="button" url={link} title={buttonLabel || 'Request a Demo'} />
            )}
          </Content>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default CallToAction;

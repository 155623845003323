import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Button from '@components/button';

export const SubmitContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `}
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    ${generateScales('border-radius', '1rem', '2rem', theme)}
    background-color: ${theme.backgroundColors.light};
    ${generateScales('padding-left', '1rem', '3rem', theme)}
    ${generateScales('padding-right', '1rem', '3rem', theme)}
    ${generateScales('padding-top', '2rem', '3rem', theme)}
    ${generateScales('padding-bottom', '2rem', '3rem', theme)}
    justify-content: center;
    align-items: center;
    min-width: 44rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      min-width: 36rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      min-width: unset;
    }
  `}
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '1.5rem', '2.5rem', theme)}
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      align-items: center;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: flex-start;
    }
  `}
`;

export const FormRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    ${generateScales('column-gap', '1rem', '1.5rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      row-gap: 1rem;
    }
  `}
`;

export const Fields = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '1rem', '1.5rem', theme)}
    width: 100%;
  `}
`;

export const ConfirmationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    row-gap: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${theme.backgroundColors.white};
    padding: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 2rem 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 2rem 1rem;
    }
  `}
`;

export const NewsletterContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    ${generateScales('column-gap', '2rem', '4rem', theme)}
    width: 100%;
    align-self: center;
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      ${generateScales('row-gap', '3rem', '4rem', theme)}
    }
  `}
`;

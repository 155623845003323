import styled, { css } from 'styled-components';
import Text from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import LinkComponent from '@components/link';
import Grid from '@components/grid';
import { StyledTextLink } from '@components/link/link.styled';

export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    ${generateScales('row-gap', '2rem', '4rem', theme)}
    ${generateScales('column-gap', '2rem', '4rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: repeat(auto-fill, minmax(0, 32rem));
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    ${generateScales(
      'font-size',
      theme.fontSize.heading5.mobile,
      theme.fontSize.heading5.desktop,
      theme
    )}
    text-align: center;
    margin: 2rem 0 1rem 0;
    display: block;
    max-width: 24.5rem;
    margin: 0 auto;
    padding: 2rem 0 1rem;
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => css`
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: ${theme.lineHeights.xl};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
      text-align: left;
      margin-left: unset;
      margin-right: unset;
    }
  `}
`;

export const StyledSubheading = styled(Text)`
  ${({ theme }) => css`
    width: 70%;
    line-height: ${theme.lineHeights.xl};
    text-align: center;
    ${generateScales('font-size', '1', '1.13rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: 75%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 80%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 90%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const TextContent = styled.div``;

export const Description = styled(Text)`
  ${({ theme }) => css`
    text-align: center;
    max-width: 24.5rem;
    margin: 0 auto;
    display: block;
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.lg};
    h3 {
      font-size: ${theme.fontSize.subheading.desktop};
      color: ${theme.colors.primary};
    }

    ul {
      margin: 2rem 0;
      padding-left: 1.5rem;
      text-align: left;

      li {
        margin-top: 0.5rem;
        list-style-type: disc;
        color: ${theme.colors.dark};
      }
    }
  `}
`;

export const ImageContainer = styled.div<{ align?: string }>`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    ${generateScales('margin-bottom', '1rem', '2rem', theme)}
    width: 100%;
    max-height: 14rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-height: 12rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-height: 10rem;
    }

    // increase height when switched to a mobile single column layout
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-height: 8rem;
      max-width: 8rem;
      margin: unset;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 34.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    ${generateScales('row-gap', '1rem', '2rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      max-width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      column-gap: 1rem;
    }
  `}
`;

export const StyledLink = styled(StyledTextLink)`
  margin: 0.5rem 0;
`;

interface LinkComponentProps {
  fontColor: keyof typeof defaultTheme.colors;
}

export const StyledLinkComponent = styled(LinkComponent)<LinkComponentProps>`
  ${({ theme, fontColor }) => {
    const { mobile, desktop } = theme.fontSize.large;
    return css`
      // typography
      ${generateScales('font-size', mobile, desktop, theme)}
      color: ${theme.colors[fontColor]};
      font-weight: 500;

      // layout
      margin: 0;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        text-align: left;
        margin-left: unset;
        margin-right: unset;
      }
    `;
  }}
`;

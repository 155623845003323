import styled, { css } from 'styled-components';
import Text from '@components/text';
import Button from '@components/button';
import { generateScales } from '@helpers/generate-media-queries';
import Input from '@components/input';
import TextArea from '@components/text-area';
import Select from '@components/select';
import Section from '@components/section';
import { underlineTransition } from '@components/link/link.styled';

export const HelperText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.additional};
    color: ${theme.colors.tertiary};
    font-size: ${theme.fontSize.small.desktop};
    margin-top: 0.5rem;
  `}
`;

export const InfoText = styled(Text)`
  text-align: center;
`;

export const ContactSection = styled(Section)`
  margin-top: 5.5rem;
`;

export const StyledCalendly = styled.div`
  ${({ theme }) => css`
    height: 792px;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 775px;
    }
  `}
`;

export const TabsContainer = styled.div`
  margin: 3rem auto 0;
  padding: 0 0.5rem;
  display: flex;
  width: fit-content;
  gap: 0.25rem;

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.muted};
    border-radius: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-width: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      border-radius: 2rem;
      gap: 0;
      flex-direction: column;
      width: 100%;
    }
  `}
`;

export const StyledTab = styled.button<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    margin: 0.5rem 0;
    padding: 0 1.5rem;
    height: 3rem;
    border: none;
    outline: none;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)};
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    line-height: 1.25;
    cursor: pointer;

    background-color: ${isActive ? theme.colors.primary : theme.colors.transparent};
    color: ${isActive ? theme.colors.white : theme.colors.primary};
    border-radius: ${theme.borderRadius.large};

    &:hover {
      background-color: ${isActive ? theme.colors.primary : theme.colors.lightGreen};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0 1rem;
      margin: 0 0.5rem;
      height: 3rem;

      &:first-child {
        margin-top: 0.5rem;
      }

      &:last-child {
        margin-bottom: 0.5rem;
      }
    }
  `}
`;

export const TabContent = styled.div<{ isActive: boolean }>`
  margin-bottom: 2rem;

  ${({ theme, isActive }) => css`
    display: ${isActive ? 'block' : 'none'};

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: 2rem;
    }
  `}
`;

export const TabContentForm = styled(TabContent)`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${({ theme, isActive }) => css`
    display: ${isActive ? 'block' : 'none'};

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: 2rem;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    /* display: flex;
    flex-direction: column;
    row-gap: 2rem; */
    max-width: 768px;
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 4rem;
    }
  `}
`;

export const HelperContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      row-gap: 2.5rem;
    }
  `}
`;

export const FooterContainer = styled.div`
  ${({ theme }) => css`
    display: none;
    flex-direction: row;
    column-gap: 2.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: flex;
      column-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      row-gap: 2.5rem;
    }
  `}
`;

export const HelperItemContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 0.75rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
    }
  `}
`;

export const ImageContainer = styled.div`
  height: 3rem;
  max-width: 3rem;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const StyledReCaptcha = styled.div`
  display: flex;
  justify-content: center;
`;

export const HelperTextContainer = styled.div`
  ${({ theme }) => css`
    max-width: 19.25rem;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: 0.5rem;
    ${generateScales(
      'font-size',
      theme.fontSize.heading6.mobile,
      theme.fontSize.body.desktop,
      theme
    )};
    color: ${theme.colors.black};
  `}
`;

export const StyledDescription = styled(Text)`
  ${({ theme }) => css`
    a {
      ${underlineTransition}
      color: ${theme.colors.secondary};
    }
    ${generateScales(
      'font-size',
      theme.fontSize.small.mobile,
      theme.fontSize.small.desktop,
      theme
    )};
  `}
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 2rem;
  width: 100%;
  flex: 1;
`;

export const FormRow = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.additional};
    margin-bottom: 0.5rem;
    font-weight: ${theme.fontWeights.normal};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.small.desktop, theme)}
  `}
`;

export const MessageContainer = styled.div`
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  max-width: 40rem;
  text-align: center;
`;

export const StyledInput = styled(Input)`
  ${({ theme }) => css`
    width: 100%;
    padding-left: 0.5rem;
    border-radius: 0.125rem;
    border: 1px solid ${theme.colors.light};
    color: ${theme.colors.primary};
    max-height: 2.75rem;

    :focus {
      border-color: ${theme.colors.dark};
    }

    ::-webkit-input-placeholder {
      color: ${theme.colors.secondary};
    }

    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)};
  `}
`;

export const StyledTextArea = styled(TextArea)`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 0.125rem;
    border: 1px solid ${theme.colors.light};
    color: ${theme.colors.primary};

    :focus {
      border-color: ${theme.colors.dark};
    }

    ::-webkit-input-placeholder {
      color: ${theme.colors.secondary};
    }

    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)};
  `}
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.primary};
    max-width: 16rem;
    border-radius: ${theme.borderRadius.large};
    font-weight: 500;
    border: none;
    margin: 0 auto;
    background-color: ${theme.colors.accent};

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }

    :hover {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;

import React from 'react';
import { defaultTheme } from '@styles/theme.styled';

interface ArrowProps {
  strokeColor?: keyof typeof defaultTheme.colors;
}

const BackArrow = ({ strokeColor }: ArrowProps): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 8H1M1 8L8 15M1 8L8 1"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackArrow;

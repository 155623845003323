import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import {
  TextContent,
  PartnerSection,
  SectionTitle,
  SectionDescription,
  Container,
  BlockImage,
  BlockTitle,
  BlockDescription,
  StyledGrid,
  Block,
} from './partner-advantage.styled';

const partnersAdvantageIdGenerator = generateId();

interface PartnerAdvantageItemType {
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  image?: ImageProps;
}

interface PartnerAdvantageProps {
  title?: string;
  description?: RichTextBlock[];
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  items?: PartnerAdvantageItemType[];
}

const PartnerAdvantage = ({
  title,
  description,
  backgroundColor = 'transparent',
  items = [],
}: PartnerAdvantageProps): JSX.Element => (
  <PartnerSection backgroundColor={backgroundColor}>
    <Wrapper>
      <Container>
        <TextContent>
          {!!title && <SectionTitle type="title" tag="h2" fontSize="heading2" stringText={title} />}
          {!!description && (
            <SectionDescription type="description" fontSize="subheading" richText={description} />
          )}
        </TextContent>
        <StyledGrid>
          {items?.map((item) => (
            <Block key={partnersAdvantageIdGenerator.next().value}>
              {!!(item.image?.gatsbyImageData || item.image?.url) && (
                <BlockImage
                  gatsbyImageData={item.image?.gatsbyImageData}
                  url={item.image?.url}
                  alt={item.image?.alt || ''}
                  objectFit="contain"
                />
              )}
              {!!item.title && (
                <BlockTitle
                  type="title"
                  tag="h3"
                  fontSize="subheading"
                  stringText={item.title.text}
                  marginBottom="0.5rem"
                />
              )}
              {!!item.description && (
                <BlockDescription type="description" richText={item.description.richText} />
              )}
            </Block>
          ))}
        </StyledGrid>
      </Container>
    </Wrapper>
  </PartnerSection>
);

export default PartnerAdvantage;

import React from 'react';

const SquareArrow = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.875 10L18.875 1V8V1H11.875"
      stroke="#AA1EF5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 1H2.875C1.77 1 0.875 1.895 0.875 3V17C0.875 18.105 1.77 19 2.875 19H16.875C17.98 19 18.875 18.105 18.875 17V13"
      stroke="#AA1EF5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SquareArrow;

import React from 'react';
import Wrapper from '@components/wrapper.styled';
import getFormatDate from '@helpers/get-format-date';
import { Container, Date } from './last-updated-date.styled';

interface AlignProps {
  align?: string;
  lastPublicationDate: string;
}

const LastPublicationDate = ({ align, lastPublicationDate }: AlignProps): JSX.Element => {
  if (!align && !lastPublicationDate) {
    return <></>;
  }

  return (
    <Wrapper>
      <Container align={align}>
        {lastPublicationDate && <Date>Last Updated: {getFormatDate(lastPublicationDate)}</Date>}
      </Container>
    </Wrapper>
  );
};

export default LastPublicationDate;

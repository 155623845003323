import styled, { css } from 'styled-components';

const linePxWidth = 2;

export const Line = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: ${linePxWidth}px;
    background: ${theme.colors.white};
    border-radius: ${linePxWidth / 2}px;
    transition: all ${theme.transitions.normal};
    position: relative;
    transform-origin: 1px;
  `}
`;

interface BurgerButtonProps {
  isActive: boolean;
  layoutType: 'dark' | 'light';
}

export const BurgerButton = styled.div<BurgerButtonProps>`
  display: none;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    ${({ theme, isActive, layoutType }) => css`
      position: relative;
      width: 2rem;
      height: 2rem;
      padding: 0.25rem;
      border-radius: 100%;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      ${Line} {
        background: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
      }

      ${isActive &&
      css`
        ${Line} {
          :nth-child(1) {
            transform: translateX(2.5px) rotate(45deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: translateX(20px);
          }

          :nth-child(3) {
            transform: translateX(2.5px) rotate(-45deg);
          }
        }
      `}
    `}
  }
`;

interface MobileContentProps {
  isVisible?: boolean;
  headerHeight?: number;
  layoutType: 'dark' | 'light';
}

export const MobileContent = styled.ul<MobileContentProps>`
  ${({ theme, isVisible, headerHeight = 118, layoutType }) => css`
    display: none;
    padding: 0 0 2.5rem 0;
    margin: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    z-index: ${theme.zIndex.navbar - 1};
    transform: translateY(-100%);
    background: ${layoutType === 'dark' ? theme.colors.primary : theme.colors.white};
    filter: drop-shadow(${theme.shadows.normal});
    transition: transform ${theme.transitions.normal}, visibility, opacity ${theme.transitions.long};
    position: fixed;
    padding-top: ${headerHeight}px;
    width: 100%;
    visibility: hidden;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }

    ${isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    `};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: initial;
    }
  `}
`;

export const MobileMenu = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

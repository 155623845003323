import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import LinkComponent from '@components/link';
import Grid from '@components/grid';

export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-wrap: wrap;
      ${generateScales('row-gap', '1.5rem', '3rem', theme)}
    }
  `}
`;

export const StyledLink = styled(LinkComponent)`
  ${({ theme }) => css`
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    ${generateScales('margin-top', '0.5rem', '2rem', theme)}
  `}
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 40em;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 3rem;
    max-width: 3rem;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 2.75rem;
      max-width: 2.75rem;
      width: 100%;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    column-gap: 1rem;
    max-width: 24.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 0.75rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      max-width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 100%;
      flex-direction: row;
      column-gap: 0.75rem;
      row-gap: 0.25rem;
    }
  `}
`;

export const Enclosure = styled.div<{ isEnclosed: boolean }>`
  ${({ theme, isEnclosed }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '2rem', '3rem', theme)}

    ${isEnclosed &&
    css`
      background: linear-gradient(
        180deg,
        ${theme.backgroundColors.white} 18.37%,
        ${theme.backgroundColors.light} 100%
      );
      border-radius: ${theme.borderRadius.small};
      box-shadow: ${theme.shadows.normal};
      ${generateScales('padding-top', '1.5rem', '5rem', theme)}
      ${generateScales('padding-bottom', '1.5rem', '5rem', theme)}
      ${generateScales('padding-right', '1rem', '2.5rem', theme)}
      ${generateScales('padding-left', '1rem', '2.5rem', theme)}
    `}
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      max-width: 100%;
    }
  `}
`;

export const Line = styled.div`
  ${({ theme }) => `
    display: flex;
    background: linear-gradient(45deg, ${theme.colors.primary}, ${theme.colors.primaryLight});
    font-size: 0;
    width: 3rem;
    border-radius: ${theme.borderRadius.large};
    margin: 0 auto;
    min-height: 0.5rem;
    ${generateScales('margin-top', '2.5rem', '5rem', theme)}
  `}
`;

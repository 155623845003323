import styled from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';
import Section from '@components/section';
import Grid from '@components/grid';
import { defaultTheme } from '@styles/theme.styled';

export const StyledGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledSection = styled(Section)<{
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
}>`
  ${({ theme, backgroundColor }) => `
      width: 100%;
      background-color: ${
        backgroundColor ? theme.backgroundColors[backgroundColor] : 'transparent'
      };
  `}
`;

export const StyledDescription = styled(Text)`
  margin-top: 0;
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.black};
    ${generateScales('font-size', '0.8375rem', '1.25rem', theme)}
    margin: 1.25rem 0 1rem 0; 
    display: block;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => `
    color: ${theme.colors.black};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.lg};
  `}
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 3rem;
  max-width: 3rem;
  border-radius: 100%;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 21rem;
  ${({ theme }) => `
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      max-width: 100%;
    }
  `}
`;

export const StyledImage = styled(Image)`
  border-radius: 100%;
`;

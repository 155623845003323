import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Text from '@components/text';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { RichTextContainer } from '@components/paragraphs/paragraphs.shared.styled';

const PositionSection = styled(Section)`
  margin-top: 2.5rem;
`;

const PositionRichText = styled(RichTextContainer)`
  ul,
  ol {
    margin-left: 1rem;
  }
`;

const PositionWrapper = styled(Wrapper)`
  max-width: 48rem;
`;

interface PositionProps {
  edges: {
    node: {
      data: {
        title: {
          text: string;
        };
        meta_description?: {
          text: string;
        };
        meta_image?: {
          url: string;
          alt: string;
        };
        job_title?: {
          richText: RichTextBlock[];
        };
        photo: ImageProps;
        description: {
          richText: RichTextBlock[];
        };
        layout_type: 'dark' | 'light';
      };
    };
  }[];
}

interface PositionInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    allPrismicPosition: PositionProps;
  };
}

const Position = ({ data }: PositionInterface): JSX.Element | null => {
  if (!data) return null;

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    allPrismicPosition: { edges },
  } = data;

  const { title, meta_description, meta_image, description, layout_type } = edges[0].node.data;

  return (
    <Layout layoutType={layout_type}>
      <SEO
        title={title.text}
        description={meta_description?.text}
        image={meta_image}
        faviconHref={favicon?.url || ''}
      />
      <PositionSection backgroundColor="white">
        <PositionWrapper>
          <Text type="title" fontSize="heading2" stringText={title?.text} />
          <PositionRichText>
            <Text type="description" richText={description.richText} />
          </PositionRichText>
        </PositionWrapper>
      </PositionSection>
    </Layout>
  );
};

export default Position;

export const query = graphql`
  query PositionQuery($uid: String) {
    prismicGeneralConfig {
      data {
        favicon {
          url
        }
      }
    }
    allPrismicPosition(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            title {
              text
            }
            meta_description {
              text
            }
            meta_image {
              url
              alt
            }
            job_title {
              richText
            }
            photo {
              url
              fluid(maxWidth: 960) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            description {
              richText
            }
            layout_type
          }
        }
      }
    }
  }
`;

import styled, { css, keyframes } from 'styled-components';
import Image from '@components/image';
import Text from '@components/text';
import LinkComponent from '@components/link';
import { generateScales } from '@helpers/generate-media-queries';
import { underlineTransition } from '@components/link/link.styled';
import { Wrapper } from '@components/wrapper.styled';

const fadeIn = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform:translateX(4rem);
  }
  51% {
    opacity: 0;
    transform:translateX(-4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const StyledItemTitle = styled(Text)`
  ${({ theme }) => css`
    margin: 0;
    display: inline-block;
    cursor: pointer;
    ${underlineTransition}

    &:hover {
      color: ${theme.colors.secondary};
    }
  `}
`;

export const StyledDescription = styled(Text)`
  ${({ theme }) => css`
    display: block;
    ${generateScales('margin-top', '0.25rem', '0.5rem', theme)}
  `}
`;

export const OpenedSubheading = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    padding: 1.5rem 1rem;
    color: ${theme.colors.primary};
    border-left: 0.5rem solid transparent;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 1rem 1rem;
      padding-bottom: 0;
    }

    ${isOpen === true
      ? css`
          // active styling
          border-left: 0.5rem solid ${theme.colors.secondary};
          background: linear-gradient(to right, #dbffe7, rgba(219, 255, 231, 0));
          @media only screen and (max-width: ${theme.breakpoints.md}) {
            padding: 1rem 1rem;
          }

          // compensate for missing bottom padding for non-active items
          :not(:first-of-type) {
            margin-top: 1rem;

            @media only screen and (max-width: ${theme.breakpoints.md}) {
              margin-top: 1rem;
            }
          }
        `
      : css`
          // remove padding to tighten spacing
          padding-bottom: 0;
        `}
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    height: fit-content;
    display: flex;
    ${generateScales('column-gap', '2rem', '4rem', theme)}
    transition: all 0.25s ease-in-out;
    align-items: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
      row-gap: 2rem;
    }
  `}
`;

export const Content = styled.div`
  height: 100%;
  min-width: 24rem;
  max-width: 30rem;
  width: 100%;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
      margin-top: 0;
      min-width: unset;
    }
  `}
`;

interface ImageContainerProps {
  $animate: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  ${({ $animate, theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    ${$animate &&
    css`
      animation: 0.5s ${fadeIn} alternate ease-in-out;
    `}
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    max-height: 24rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 20rem;
    }
  `}
`;

export const StyledMobileImage = styled(Image)`
  ${({ theme }) => css`
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: block;
      width: 100%;
      height: 20rem;
      margin: 1.5rem 0 1.25rem 0;
      height: 14rem;

      img {
        object-position: 1rem center;
      }
    }
  `}
`;

export const StyledLinkComponent = styled(LinkComponent)`
  ${({ theme }) => css`
    width: fit-content;
    margin: 0;
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.desktop, theme)}
  `}
`;

export const StyledItemLinkComponent = styled(LinkComponent)`
  ${({ theme }) => css`
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    margin: 0;
  `}
`;

export const StyledWrapper = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${generateScales('row-gap', '1.5rem', '4rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
      justify-content: flex-start;
    }
  `}
`;

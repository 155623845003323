import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import { wrapperPadding } from '@components/wrapper.styled';

export const StyledText = styled(Text)`
  ${({ theme }) => `
    ${generateScales(
      'font-size',
      theme.fontSize.heading2.mobile,
      theme.fontSize.heading2.desktop,
      theme
    )};
    text-align: center;
    color: ${theme.colors.primary};
    ${generateScales('margin-bottom', '2rem', '4rem', theme)}
  `}
`;

export const Subheading = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.secondary};
    text-align: center;
    ${generateScales(
      'font-size',
      theme.fontSize.heading4.mobile,
      theme.fontSize.heading4.desktop,
      theme
    )}
    ${generateScales('margin-bottom', '2rem', '4rem', theme)}
  `}
`;

interface GridProps {
  itemsPerRow?: number;
}

export const LeaderGrid = styled.div<GridProps>`
  ${({ theme, itemsPerRow = 4 }) => css`
    display: grid;
    grid-template-columns: repeat(${itemsPerRow}, 1fr);
    column-gap: 1rem;
    row-gap: 3rem;
    margin: 0 auto;

    ${itemsPerRow === 3
      ? css`
          width: 65%;

          .bio-card .bio-image {
            height: 20rem;

            @media only screen and (max-width: ${theme.breakpoints.xl}) {
              height: 18rem;
            }

            @media only screen and (max-width: ${theme.breakpoints.lg}) {
              height: 18rem;
            }

            @media only screen and (max-width: ${theme.breakpoints.sm}) {
              height: 20rem;
              width: calc(100% + 2rem);
              margin-left: -${wrapperPadding};
              margin-right: -${wrapperPadding};
            }
          }

          @media only screen and (max-width: ${theme.breakpoints.xl}) {
            width: 75%;
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: ${theme.breakpoints.lg}) {
            width: 100%;
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
          }
        `
      : css`
          width: 65%;

          .bio-card .bio-image {
            height: 12rem;

            @media only screen and (max-width: ${theme.breakpoints.xl}) {
              height: 10rem;
            }

            @media only screen and (max-width: ${theme.breakpoints.lg}) {
              height: 9rem;
            }
            @media only screen and (max-width: ${theme.breakpoints.md}) {
              height: 12rem;
            }
          }

          @media only screen and (max-width: ${theme.breakpoints.xl}) {
            width: 75%;
          }

          @media only screen and (max-width: ${theme.breakpoints.lg}) {
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
          }

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            width: 100%;
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            grid-template-columns: repeat(2, 1fr);
          }
        `}
  `}
`;

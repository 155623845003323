import { css } from 'styled-components';

const ColorVars = css`
  --bodyColor: #555555;
  --muted: #999999;
  --defaultBlack: #000000;
  --black: #151515;
  --white: #ffffff;
  --light: #eeeeee;
  --primary: #ee3932;
  --secondary: #f6817e;
  --default: #13294e;
  --blue: #44c8e1;
  --darkGray: #666666;
  --inactive: #cccccc;
  --violet: #5c257b;
  --lightBlue: #c7eaf2;
  --warmWight: #fcfafa;
  --nearBlack: #070d19;
`;

export default ColorVars;

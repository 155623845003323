import React from 'react';
import Image, { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import Section from '@components/section';
import Grid from '@components/grid';
import LinkComponent from '@components/link';
import getLinkConfig from '@helpers/get-link-config';
import {
  ImageContainer,
  StyledTitle,
  Description,
  Container,
  StyledText,
} from './transit-types.styled';

const TransitTypesIdGenerator = generateId();

export interface TransitTypesProps {
  generalTitle?: string;
  items: TransitTypesPropsItem[];
  itemsPerRow?: number;
}

export interface TransitTypesPropsItem {
  image?: ImageProps;
  title?: {
    text: string;
  };
  description?: {
    text: string;
  };
  link_label?: string;
  link?: {
    url?: string;
    uid?: string;
  };
}

const TransitTypes = ({ items, itemsPerRow = 2, generalTitle }: TransitTypesProps): JSX.Element => {
  return (
    <Section backgroundColor="white">
      <Wrapper>
        {generalTitle && (
          <StyledText fontSize="heading1" tag="h2" type="title" stringText={generalTitle} />
        )}
        <Grid itemsPerRow={itemsPerRow}>
          {items?.map(({ image, title, description, link_label, link }) => {
            const linkConfig = getLinkConfig(link);

            return (
              <Container key={TransitTypesIdGenerator.next().value}>
                {image?.url && (
                  <ImageContainer>
                    <Image url={image?.url} alt={image?.alt} />
                  </ImageContainer>
                )}
                {title && (
                  <StyledTitle
                    tag="h3"
                    fontColor="dark"
                    type="title"
                    fontSize="heading5"
                    stringText={title.text}
                  />
                )}
                {description && <Description>{description?.text}</Description>}
                {!!linkConfig.url && (
                  <LinkComponent
                    url={linkConfig.url}
                    isExternal={linkConfig.isExternal}
                    title={link_label || 'Follow the link'}
                    type="text"
                  />
                )}
              </Container>
            );
          })}
        </Grid>
      </Wrapper>
    </Section>
  );
};

export default TransitTypes;

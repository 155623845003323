import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Wrapper from '@components/wrapper.styled';

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    padding: 5rem 2rem 4rem 2rem;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 2.5rem 1.5rem 2rem 1.5rem;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('font-size', '0.8375rem', '1.25rem', theme)}
    display: block;
    margin-bottom: 0.5rem;
    line-height: 2.13rem;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 4rem 5rem;
    background: ${theme.colors.white};
    margin: 0 auto;
    min-height: 0.5rem;
    border-radius: ${theme.borderRadius.small};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0 1rem 2.5rem;
    }
  `}
`;

export const StyledWrapper = styled(Wrapper)`
  ${({ theme }) => `
    padding: 3rem;
    max-width: 64rem;
    border-radius: ${theme.borderRadius.normal};
    background: linear-gradient(270deg, ${theme.colors.primary}, ${theme.colors.secondary}, ${theme.colors.primaryLight}, ${theme.colors.light});

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 2rem;
      border-radius: ${theme.borderRadius.small};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 1.5rem 1rem;
      border-radius: unset;
    }
  `}
`;

import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import BackgroundMask from '@images/background-mask.svg';
import { defaultTheme } from '@styles/theme.styled';
import { underlineTransition } from '@components/link/link.styled';
import { wrapperWidths } from '@components/wrapper.styled';

const createItemLinkCSS = (theme: typeof defaultTheme) =>
  css`
    margin-bottom: ${theme.spacing.xs};
    color: ${theme.colors.primary};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', '0.9375rem', '1rem', theme)};
    line-height: ${theme.lineHeights.sm};
    text-decoration: none;
    cursor: pointer;
    ${underlineTransition}

    ${underlineTransition}
  `;

const createLinkCSS = (theme: typeof defaultTheme) =>
  css`
    margin-bottom: 0;
    text-align: center;
    ${underlineTransition}

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `;

export const ItemLink = styled(Link)`
  ${({ theme }) => createItemLinkCSS(theme)}
`;

export const ExternalItemLink = styled.a`
  ${({ theme }) => createItemLinkCSS(theme)}
`;

export const StyledLink = styled(ItemLink)`
  ${({ theme }) => createLinkCSS(theme)}
`;

export const ExternalLink = styled(ExternalItemLink)`
  ${({ theme }) => createLinkCSS(theme)}
`;

export const StyledFooter = styled.footer`
  ${({ theme }) => css`
    position: relative;
    z-index: 98;
    background-color: ${theme.backgroundColors.light};
    color: ${theme.colors.secondary};
    display: flex;
    flex-direction: column;
    row-gap: 7rem;
    padding: 7rem 0;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 6rem 0;
      row-gap: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 5rem 0;
      row-gap: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 4rem 0;
      row-gap: 4rem;
      background-color: ${theme.backgroundColors.white};
    }
  `}
`;

export const Shape = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.backgroundColors.white};
    mask: url(${BackgroundMask}) no-repeat bottom 3.5rem right
      calc(50% - (${wrapperWidths.xxl} * (0.5 / 2))) / 3440px 2880px;

    @media (max-width: ${theme.breakpoints.xl}) {
      mask-position: bottom 3rem right calc(50% - (${wrapperWidths.xl} * (0.5 / 2)));
      padding: 6rem 0;
      row-gap: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      mask-position: bottom 2.5rem right calc(50% - (${wrapperWidths.lg} * (0.5 / 2)));
      mask-size: 1720px 1440px;
      padding: 5rem 0;
      row-gap: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 4rem 0;
      row-gap: 4rem;
      mask-position: bottom 2rem right calc(50% - (${wrapperWidths.md} * (0.5 / 2)));
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      mask-size: 1505px 1260px;
      mask-position: bottom 2rem center;
    }
  `}
`;

export const TopContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      row-gap: 3rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
    }
  `}
`;

export const LogoContainer = styled.div`
  ${({ theme }) => `
    width: calc(50% - ${theme.spacing.md});

    @media (max-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  `}
`;

export const Logo = styled.div<{ imageUrl: string }>`
  ${({ theme, imageUrl }) => css`
    width: 100%;
    height: 2.5rem;
    background-color: ${theme.colors.secondary};
    mask: url(${imageUrl}) no-repeat left center / contain;
    -webkit-mask: url(${imageUrl}) no-repeat left center / contain;
    margin-bottom: ${theme.spacing.sm};
  `}
`;

export const LogoDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-family: ${theme.fontFamily.text};
    ${generateScales(
      'font-size',
      theme.fontSize.small.mobile,
      theme.fontSize.small.desktop,
      theme
    )};
    line-height: ${theme.lineHeights.lg};

    @media (max-width: ${theme.breakpoints.lg}) {
      width: 14rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const LinksGrid = styled.nav`
  ${({ theme }) => `
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: ${theme.spacing.md};
    row-gap: 3rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const LinksGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemTitle = styled(Text)`
  margin-bottom: 1.25rem;
`;

export const LinkList = styled.ul``;

export const LinkListItem = styled.li`
  :not(:last-of-type) {
    margin-bottom: 0.75rem;
  }
`;

export const BottomContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    ${generateScales(
      'font-size',
      theme.fontSize.small.mobile,
      theme.fontSize.small.desktop,
      theme
    )};

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 1.5rem;
    }
  `}
`;

export const StyledSpan = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.secondary};
    font-family: ${theme.fontFamily.text};
    line-height: ${theme.lineHeights.sm};
    text-align: center;
  `}
`;

export const BottomLinksContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 1.25rem;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 0;
      flex-direction: column;
      row-gap: 1.5rem;
    }
  `}
`;

export const BottomLinks = styled.div`
  display: flex;
  column-gap: 1.25rem;
`;

export const SocialWrapper = styled.div`
  display: flex;
  column-gap: 1.25rem;
`;

export const MetaContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      row-gap: 2rem;
    }
  `}
`;

export const SocialMedia = styled.a`
  ${({ theme }) => css`
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 0.875rem;
    background-color: ${theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: ${theme.colors.primary};
    }

    :last-child {
      margin-right: 0;
    }

    img {
      height: 1rem;
      width: 1rem;
    }
  `}
`;

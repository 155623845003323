import React from 'react';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import { StyledImage } from './image-slice.styled';

export interface ImageSliceProps {
  image?: ImageProps;
  layoutType?: string;
}

const ImageSlice = ({ image, layoutType }: ImageSliceProps): JSX.Element => {
  if (!image) {
    return <></>;
  }

  const renderImage = (): JSX.Element => (
    <>
      {(image?.gatsbyImageData || image?.url) && (
        <StyledImage
          url={image.url}
          alt={image.alt || ''}
          gatsbyImageData={image.gatsbyImageData}
          objectFit="cover"
        />
      )}
    </>
  );

  return (
    <Section backgroundColor="white">
      {layoutType === 'fullWidth' ? renderImage() : renderImage()}
    </Section>
  );
};

export default ImageSlice;

import styled, { css, keyframes } from 'styled-components';
import Wrapper from '@components/wrapper.styled';
import { StyledBorderedLink } from '@helpers/get-navbar-links';

export const headerHeight = '5.5rem';

export const HeaderWrapper = styled(Wrapper)`
  position: relative;
  z-index: 2;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

interface HeaderBgColorProps {
  $backgroundColor: string;
  $visible: boolean;
}

export const HeaderBgColor = styled.div<HeaderBgColorProps>`
  ${({ $backgroundColor, $visible }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${$backgroundColor};
    z-index: 1;
    box-shadow: 0.25rem 0.5rem 2rem rgb(0 0 0 / 25%);
    ${$visible
      ? css`
          transition: none;
          opacity: 1;
        `
      : css`
          transition: opacity 0.25s ease-in-out;
          opacity: 0;
        `}
  `}
`;

interface StyledHeaderProps {
  $isActive: boolean;
  layoutType: 'dark' | 'light';
  $visible: boolean;
}

export const StyledHeader = styled.nav<StyledHeaderProps>`
  ${({ theme, layoutType, $isActive, $visible }) => css`
    display: flex;
    padding: 0.5rem 0;
    align-items: center;
    width: 100%;
    min-height: ${headerHeight};
    max-height: max-content;
    position: absolute;
    top: 0;
    z-index: ${theme.zIndex.navbar};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      min-height: 5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-height: 4rem;
    }

    ${$visible
      ? css`
          position: fixed;
          animation: 0.25s ${fadeIn} ease-in;
        `
      : css`
          position: absolute;
        `}

    ${$isActive &&
    css`
      transition-delay: 0;
      background-color: ${layoutType === 'dark'
        ? theme.colors.secondary
        : theme.colors.white} !important;
    `}
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface LogoProps {
  imageUrl: string;
  layoutType: 'dark' | 'light';
}

export const Logo = styled.div<LogoProps>`
  ${({ theme, imageUrl, layoutType }) => css`
    width: 10.67rem;
    height: 2.5rem;
    background-color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.secondary};
    mask: url(${imageUrl}) no-repeat center / contain;
    -webkit-mask: url(${imageUrl}) no-repeat center / contain;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 7rem;
      height: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 7.03125rem;
      height: 1.640625rem;
    }
  `}
`;

export const Menu = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    column-gap: 2rem;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      column-gap: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      column-gap: 1rem;
      display: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const MainActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
`;

export const MobileContactButton = styled(StyledBorderedLink)`
  ${({ theme }) => css`
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: flex;
    }
  `}
`;

import styled from 'styled-components';
import Image from '@components/image';
import Section from '@components/section';
import { generateScales } from '@helpers/generate-media-queries';

// removes top padding to make more flush with page hero
// todo: apply conditionally
export const StyledSection = styled(Section)`
  padding-top: 0;
`;

export const SlickContainer = styled.div`
  margin: 0 0.75rem;
`;

export const SlickImage = styled(Image)`
  ${({ theme }) => `
    ${generateScales('width', '18rem', '24.5rem', theme)}
    height: auto;
    width: 24.5rem;
    box-shadow: ${theme.shadows.small};
    border-radius: 0.5rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: calc(100vw - 2rem);
    }
  `}
`;

export const Container = styled.div<{ align?: string }>`
  margin: 0;
  width: 100vw;

  .slick-track {
    display: flex;
    ${({ align }) => (align === 'Top' ? `align-items: flex-start;` : `align-items: flex-end;`)}
  }

  .slick-list {
    overflow: visible;
  }
`;

import styled from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';

export const StyledSVG = styled.svg<{
  color?: keyof typeof defaultTheme.colors;
  fill?: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, color, fill }) => `
    stroke-width: ${theme.borderWidths.icons};
    stroke: ${color ? theme.colors[color] : 'none'};
    fill: ${fill ? theme.colors[fill] : 'none'};
`}
`;

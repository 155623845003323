const getFormatDate = (stringDate: string): string => {
  const date = new Date(stringDate);

  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(date);
};

export default getFormatDate;

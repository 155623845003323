import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Prismic from '@prismicio/client';
import { useLocation } from '@reach/router';
import generateId from '@helpers/generate-id';
import { resourceCenterUrl } from '@constants/api';
import Section from '@components/section';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import { ImageProps } from '@components/image';
import getBlogType from '@helpers/get-blog-type';
import {
  PostGrid,
  StyledImage,
  StyledLink,
  StyledTitle,
  DummyImageContainer,
  DummyWrapper,
  DummyTitle,
  DummyLink,
  PostWrapper,
} from './resource-posts.styled';

interface PostDataType {
  post_title: {
    text: string;
  }[];
  post_description: {
    text: string;
  }[];
  post_image?: ImageProps;
}

interface PostResultType {
  data: PostDataType;
  uid: string;
  first_publication_date: string;
  tags: string[];
  type: 'book_post' | 'white_paper_post' | 'case_study_post' | 'video_post' | 'post';
}

interface ResourceCenterItemsProps {
  image?: ImageProps;
  title?: {
    text: string;
  };
  link?: {
    uid?: string;
    url?: string;
  };
  link_label?: string;
}

interface ResourceCenterProps {
  title?: string;
  items?: ResourceCenterItemsProps[];
}

const postsListIdGenerator = generateId();

const ResourcePosts = ({ title, items = [] }: ResourceCenterProps): JSX.Element => {
  const location = useLocation();
  const confirmType = new URLSearchParams(location.search).get('type') ?? 'default';
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [posts, setPosts] = useState<ResourceCenterItemsProps[]>(items);
  const client = Prismic.client('https://tripshot-new.cdn.prismic.io/api/v2');

  const loadApiPosts = useCallback(
    async (setValues: (values: ResourceCenterItemsProps[]) => void) => {
      const allTypes = ['post', 'book_post', 'case_study_post', 'white_paper_post'];
      const allTypesFetch = allTypes
        .map((post) => [
          `my.${post}.post_title`,
          `my.${post}.post_description`,
          `my.${post}.post_image`,
        ])
        .flat();

      const allTypesFetchLinks = allTypes.map((post) => `my.${post}.post_image`);

      const response = await client.query<PostResultType>(
        [Prismic.Predicates.any('document.type', allTypes)],
        {
          orderings: '[document.first_publication_date desc]',
          fetch: allTypesFetch,
          fetchLinks: allTypesFetchLinks,
          pageSize: 3,
        }
      );

      if (response) {
        const { results } = response;
        const apiPosts = results.map((result) => result as unknown as PostResultType);

        const newResults = apiPosts.map((apiPost, index) => ({
          image: {
            url: apiPost.data.post_image?.url,
            gatsbyImageData: apiPost.data.post_image?.gatsbyImageData,
            alt: apiPost.data.post_image?.alt,
          },
          title: {
            text: apiPost.data.post_title[0].text,
          },
          link: {
            url: `${resourceCenterUrl}${getBlogType(apiPost.type)}/${apiPost.uid}`,
          },
          link_label: items[index]?.link_label ?? 'Read More',
        }));
        setValues(newResults as ResourceCenterItemsProps[]);
      }
    },
    [items]
  );

  const memoizedPosts = useMemo(() => posts, [posts]);

  useEffect(() => {
    loadApiPosts(setPosts)
      .catch((e) => console.error(e))
      .finally(() => setIsLoaded(true));
  }, [confirmType, loadApiPosts]);

  return (
    <Section backgroundColor="light">
      <Wrapper>
        {!!title && (
          <StyledTitle
            tag="h2"
            fontColor="primary"
            type="title"
            fontSize="heading3"
            stringText={title}
          />
        )}
        <PostGrid isLoaded={isLoaded}>
          {memoizedPosts.map((post) => {
            const postId = postsListIdGenerator.next().value;
            return (
              <>
                <PostWrapper key={postId}>
                  {post.image?.url ? (
                    <StyledImage
                      url={post.image.url}
                      alt={post.image.alt ?? ''}
                      gatsbyImageData={post.image.gatsbyImageData}
                    />
                  ) : (
                    <div>No Image</div>
                  )}
                  <Text
                    tag="h3"
                    fontColor="primary"
                    type="title"
                    fontSize="heading6"
                    stringText={post.title?.text}
                  />
                  <StyledLink title="View this post" href={post.link?.url}>
                    {post.link_label}
                  </StyledLink>
                </PostWrapper>
                <DummyWrapper key={postsListIdGenerator.next().value}>
                  <DummyImageContainer />
                  <DummyTitle />
                  <DummyLink />
                </DummyWrapper>
              </>
            );
          })}
        </PostGrid>
      </Wrapper>
    </Section>
  );
};

export default ResourcePosts;

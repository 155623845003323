const getBlogType = (type: string): string => {
  switch (type) {
    case 'video_post':
      return '/videos';
    case 'book_post':
      return '/books';
    case 'case_study_post':
      return '/case-studies';
    case 'white_paper_post':
      return '/white-papers';
    case 'post':
      return '/blog';
    default:
      return '/blog';
  }
};

export default getBlogType;

import { DefaultTheme } from 'styled-components';

const CURVE_VALUE = 25;

/**
 * Curved Range
 *
 * Generates array of numbers with curved values based on min and max values
 *
 * @param min mobile value
 * @param max desktop value
 * @param n number of breakpoints
 * @param curve curve value
 * @returns {Array<number>}
 */
export const curvedRange = (
  min: number,
  max: number,
  n: number,
  curve = CURVE_VALUE
): Array<number> => {
  const interpolate = (a: number, b: number, t: number) => a * (1 - t) + b * t;
  const pow = interpolate(1, curve < 0 ? 3 : 1 / 3, Math.abs(+curve || 0) / 100);
  const arr = Array(+n);

  for (let i = 0; i < arr.length; i += 1) arr[i] = interpolate(min, max, (i / (n - 1)) ** pow);

  return arr as Array<number>;
};

/**
 * Generate Scales
 *
 * Generates a series of responsive css rules based on mobile and desktop value
 *
 * @param property css property
 * @param mobileVal mobile value
 * @param desktopVal desktop value
 * @param defaultTheme default theme
 * @returns
 */
export const generateScales = (
  property: string,
  mobileVal: string,
  desktopVal: string,
  defaultTheme: DefaultTheme
): string => {
  const { breakpoints } = defaultTheme;
  const breakpointsArr = Object.keys(breakpoints);
  const n = breakpointsArr.length + 1;
  const mobileSize = parseFloat(mobileVal);
  const desktopSize = parseFloat(desktopVal);
  const expVals = curvedRange(mobileSize, desktopSize, n).reverse();

  const responsiveCSS = breakpointsArr
    .reverse()
    .map(
      (breakpoint, i) => `
    @media only screen and (max-width: ${breakpoints[breakpoint as keyof typeof breakpoints]}) {
      ${property}: ${expVals[i + 1]}rem;
    }
  `
    )
    .join('');

  return `
    ${property}: ${desktopVal};
    ${responsiveCSS}
  `;
};

// generates styles that scales property from xs to xl breakpoints
export const generateScalingQueries = (
  property: string,
  maxValue: string,
  defaultTheme: DefaultTheme
): string => {
  const { breakpoints } = defaultTheme;

  // sort theme values to sequentially generate markup
  const breakpointsSorted = Object.keys(breakpoints).sort((firstEl, secondEl) => {
    const firstBreakpoint = parseInt(breakpoints[firstEl as keyof typeof breakpoints], 10);
    const secondBreakpoint = parseInt(breakpoints[secondEl as keyof typeof breakpoints], 10);

    return firstBreakpoint - secondBreakpoint;
  });

  // generate media querie markup
  const templateLiteral = breakpointsSorted
    .map((breakpoint, i) => {
      const maxBreakpoint = breakpoint as keyof typeof breakpoints;
      const scale = breakpoint as keyof typeof breakpoints;

      // smallest breakpoint (<xs)
      if (i === 0) {
        return `
        ${property}: ${maxValue};

        @media only screen and (min-width: 0px) and (max-width: ${defaultTheme.breakpoints[maxBreakpoint]}) {
          ${property}: calc(${maxValue} * ${defaultTheme.scales[scale]});
        }
      `;
      }

      // additional breakpoint ranges (sm to xl)
      const minBeakpoint = breakpointsSorted[i - 1] as keyof typeof defaultTheme.breakpoints;
      const minWidth = parseInt(defaultTheme.breakpoints[minBeakpoint], 10);
      return `
        @media only screen and (min-width: ${minWidth + 1}px) and (max-width: ${
        defaultTheme.breakpoints[maxBreakpoint]
      }) {
          ${property}: calc(${maxValue} * ${defaultTheme.scales[scale]});
        }
      `;
    })
    .join('');

  return templateLiteral;
};

import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RichTextBlock } from 'prismic-reactjs';
import Slider from 'react-slick';
import Section from '@components/section';
import generateId from '@helpers/generate-id';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import { defaultTheme } from '@styles/theme.styled';
import {
  ArrowContent,
  NextArrow,
  PrevArrow,
  Container,
  QuoteText,
  NameText,
  StyledTitle,
  Content,
  Dots,
  SlickContainer,
} from './feedbacks.styled';

const carouselIdGenerator = generateId();

interface Feedbacks {
  title?: string;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  items?: {
    description?: {
      text: string;
    };
    title?: {
      text: string;
    };
    subheading?: {
      richText: RichTextBlock[];
    };
  }[];
}

const Feedbacks = ({
  items = [],
  title,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
}: Feedbacks): JSX.Element => {
  const settings = {
    dots: true,
    appendDots: (dot: JSX.Element) => <Dots aria-label="Navigate to the Homepage">{dot}</Dots>,
    infinite: true,
    cssEase: 'ease-out',
    slidesToShow: 1,
    centerPadding: '27.5%',
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    swipe: true,
    swipeToSlide: true,
    centerMode: true,
    speed: 500,
    arrows: true,
    nextArrow: (
      <NextArrow>
        <ArrowContent>Next</ArrowContent>
      </NextArrow>
    ),
    prevArrow: (
      <PrevArrow>
        <ArrowContent>Prev</ArrowContent>
      </PrevArrow>
    ),
    responsive: [
      {
        breakpoint: +defaultTheme.breakpoints.xxl.slice(0, -2),
        settings: {
          centerPadding: '25%',
        },
      },
      {
        breakpoint: +defaultTheme.breakpoints.xl.slice(0, -2),
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: +defaultTheme.breakpoints.lg.slice(0, -2),
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: +defaultTheme.breakpoints.md.slice(0, -2),
        settings: {
          centerPadding: '0',
          arrows: false,
        },
      },
    ],
  };

  if (items?.length === 0) {
    return <></>;
  }

  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Container>
        <Wrapper>
          <StyledTitle type="title" stringText={title} fontSize="heading3" margin="lg" />
        </Wrapper>
        {/* @ts-expect-error Server Component */}
        <Slider {...settings}>
          {items?.map((item) => (
            <SlickContainer key={carouselIdGenerator.next().value}>
              <Content>
                {!!item.description?.text && (
                  <QuoteText
                    type="title"
                    fontSize="body"
                    tag="p"
                    stringText={`${item.description.text}"`}
                  />
                )}
                {!!item.title?.text && (
                  <NameText
                    type="title"
                    fontSize="body"
                    tag="p"
                    stringText={item.title?.text}
                    fontColor="secondary"
                  />
                )}
                {!!item.subheading?.richText && (
                  <Text type="description" fontSize="small" richText={item.subheading?.richText} />
                )}
              </Content>
            </SlickContainer>
          ))}
        </Slider>
      </Container>
    </Section>
  );
};

export default Feedbacks;

import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import {
  Container,
  MiddleContainer,
  ImageBackground,
  TextContent,
  TextContainer,
  NumberContainer,
  NumberBlock,
  StyledText,
} from './graphics.styled';

interface GraphicsProps {
  title?: string;
  description?: RichTextBlock[];
  topText?: RichTextBlock[];
  rightText?: RichTextBlock[];
  leftText?: RichTextBlock[];
  bottomText?: RichTextBlock[];
  image?: ImageProps;
}

const Graphics = ({
  title,
  description,
  topText,
  rightText,
  leftText,
  bottomText,
  image,
}: GraphicsProps): JSX.Element => {
  if (!title && !description && !image && !topText && !rightText && !leftText && !leftText) {
    return <></>;
  }

  return (
    <Section backgroundColor="white">
      <Wrapper>
        <Container>
          <TextContent>
            {!!title && (
              <Text type="title" tag="h2" fontSize="heading4" stringText={title} margin="md" />
            )}
            {description && <Text type="subheading" richText={description} />}
          </TextContent>

          <ImageBackground image={image}>
            <TextContainer>
              <NumberContainer>
                <NumberBlock>1</NumberBlock>
              </NumberContainer>
              {topText && <StyledText type="description" richText={topText} />}
            </TextContainer>

            <MiddleContainer>
              <TextContainer>
                <NumberContainer>
                  <NumberBlock>2</NumberBlock>
                </NumberContainer>
                {leftText && <StyledText type="description" richText={leftText} />}
              </TextContainer>
              <TextContainer>
                <NumberContainer>
                  <NumberBlock>4</NumberBlock>
                </NumberContainer>
                {rightText && <StyledText type="description" richText={rightText} />}
              </TextContainer>
            </MiddleContainer>

            <TextContainer>
              <NumberContainer>
                <NumberBlock>3</NumberBlock>
              </NumberContainer>
              {bottomText && <StyledText type="description" richText={bottomText} />}
            </TextContainer>
          </ImageBackground>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Graphics;

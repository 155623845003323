import React from 'react';
import Image, { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import Text from '@components/text';
import Section from '@components/section';
import getLinkConfig from '@helpers/get-link-config';
import {
  ImageContainer,
  Enclosure,
  StyledLink,
  Container,
  StyledGrid,
  ContentContainer,
  TitleContainer,
  Line,
} from './relationships.styled';

const RelationshipsIdGenerator = generateId();

export interface RelationshipsProps {
  items: RelationshipsPropsItem[];
  itemsPerRow?: number;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  title?: string;
  description?: RichTextBlock[];
  isEnclosed: boolean;
}

export interface RelationshipsPropsItem {
  image?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link_label?: string;
  link?: {
    url?: string;
  };
}

const Relationships = ({
  items,
  itemsPerRow = 3,
  title,
  description,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  isEnclosed = false,
}: RelationshipsProps): JSX.Element => {
  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        <Enclosure isEnclosed={isEnclosed}>
          <TitleContainer>
            {title && (
              <Text type="title" tag="h2" stringText={title} fontSize="heading3" margin="sm" />
            )}
            {description && <Text type="subheading" richText={description} />}
          </TitleContainer>
          <StyledGrid itemsPerRow={itemsPerRow}>
            {items.map((item) => {
              const linkConfig = getLinkConfig(item.link);

              return (
                <Container key={RelationshipsIdGenerator.next().value}>
                  {item.image?.url && (
                    <ImageContainer>
                      <Image url={item.image?.url} />
                    </ImageContainer>
                  )}
                  <ContentContainer>
                    {item.title?.text && (
                      <Text
                        tag="h3"
                        fontColor="primary"
                        type="title"
                        fontSize="large"
                        stringText={item.title?.text}
                        margin="xs"
                      />
                    )}
                    {item.description?.richText && (
                      <Text
                        type="description"
                        fontSize="body"
                        richText={item.description?.richText}
                      />
                    )}
                    {!!linkConfig.url && (
                      <StyledLink
                        url={linkConfig.url}
                        isExternal={linkConfig.isExternal}
                        title={item.link_label || 'Follow the link'}
                        type="text"
                      />
                    )}
                  </ContentContainer>
                </Container>
              );
            })}
          </StyledGrid>
        </Enclosure>
        {!isEnclosed && <Line>Line</Line>}
      </Wrapper>
    </Section>
  );
};

export default Relationships;

import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import Image from '@components/image';

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      row-gap: 0.5rem;
    }
  `}
`;

export const StyledSlider = styled(Slider)`
  margin: 0;
  width: 100%;

  .slick-track {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;

    ${({ theme }) => css`
      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        gap: 3rem;
      }
    `}
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    width: auto !important;
  }
`;

export const HeadingContainer = styled.div`
  text-align: center;
  ${({ theme }) => css`
    margin: 0 0 ${theme.padding.md};
  `}
`;

export const PartnerImage = styled(Image)`
  margin: 0 auto;
  max-width: 12rem;
  max-height: 7rem;
`;

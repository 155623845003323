import styled, { css } from 'styled-components';
import Text from '@components/text';
import { MediumButton } from '@styles/button.styled';
import Arrow from '@components/svg-icons/arrow';
import { generateScales } from '@helpers/generate-media-queries';
import { underlineTransition } from '@components/link/link.styled';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled.a`
  ${MediumButton}
  ${({ theme }) => `]
    ${generateScales('font-size', '1rem', '0.875rem', theme)}
  `}
  width: auto;
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.75rem;
    ${generateScales('margin-top', '2.5rem', '4rem', theme)}

    @media (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 0.625rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60rem;
  padding: 0 1rem;
`;

export const ImageContainer = styled.div`
  margin-top: 3.5rem;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  div {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const StyledExternalLink = styled.a`
  ${({ theme }) => `
    padding: 0.5rem;
    margin: 0 0.5rem;
    text-decoration: none;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.lineHeights.xs};
    line-height: ${theme.lineHeights.xs};

    :hover {
      text-decoration: underline;
    }

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }
  `}
`;

export const MethodsContainer = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  margin: 0 auto;
  margin-top: 3.5rem;

  ${({ theme }) => `
    padding-bottom: 8rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding-bottom: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding-bottom: 4rem;
    }
  `}
`;

export const PostContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 64rem;
  align-self: center;
  margin: 0 auto;
  margin-top: 3.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      align-items: center;
    }

    padding-bottom: 8rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding-bottom: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding-bottom: 4rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column-reverse;
    }
  `}
`;

export const MetaContainer = styled.div`
  margin: 0 4rem 0 0;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.lg}) {
      margin: 0;
      padding: 0;
      ${generateScales('margin-top', '2.5rem', '4rem', theme)}
      ${generateScales('padding-top', '2.5rem', '4rem', theme)}
      width: 100%;
      display: flex;
      border-top: 1px solid ${theme.colors.dark};
    }
  `}
`;

export const MethodMetaContainer = styled.div`
  margin: 0 0 0 4rem;

  ${({ theme }) => `
    box-shadow: ${theme.shadows.small};
    border-radius: ${theme.borderRadius.small};
    padding: 2rem 1.5rem;
    @media (max-width: ${theme.breakpoints.lg}) {
      margin: 0;
      padding: 0;
      ${generateScales('margin-top', '2.5rem', '4rem', theme)}
      ${generateScales('padding-top', '2.5rem', '4rem', theme)}
      width: 100%;
      display: flex;
      border-top: 1px solid ${theme.colors.dark};
    }
  `}
`;

export const MetaInfo = styled.div`
  ${({ theme }) => `
    width: 17rem;
    height: max-content;
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: 1;
    color: ${theme.colors.muted};

    @media (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `}
`;

export const Categories = styled.div`
  margin: 2rem 0 0 0;
`;

export const TopMeta = styled.div``;

export const SubMeta = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: 0 0 1.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 0;
    }
  `}
`;

export const TextSubMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AuthorPhotoConteiner = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  overflow: hidden;

  div {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  + ${TextSubMeta} {
    margin: 0 0 0 0.75rem;
  }
`;

export const AuthorName = styled.div`
  ${({ theme }) => `
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    line-height: 1;
    color: ${theme.colors.dark};
  `}
`;

export const PrefixAuthorName = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.tertiary};
  `}
`;

export const Date = styled.div`
  ${({ theme }) => `
    margin-top: 0.5rem;
    color: ${theme.colors.tertiary};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: 1;
  `}
`;

export const PostBody = styled.div`
  flex: 1 auto;
`;

export const TitleContainer = styled.div`
  width: 100%;
  ${({ theme }) => `
    border-bottom: 1px solid ${theme.colors.tertiary};
  `}
`;

export const ResearcherName = styled.div`
  ${({ theme }) => `
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    line-height: 1;
    color: ${theme.colors.dark};

    a, a:visited {
      color: ${theme.colors.tertiary};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export const CreditContainer = styled.div`
  width: 100%;
  margin: 2rem 0 0;

  ${({ theme }) => `
    color: ${theme.colors.dark};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: ${theme.lineHeights.lg};

    a, a:visited {
      color: ${theme.colors.tertiary};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export const CreditTitle = styled.h3`
  ${({ theme }) => `
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.mobile, theme)};
    line-height: ${theme.lineHeights.sm};
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 0.5rem;
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    ${generateScales(
      'font-size',
      theme.fontSize.heading3.mobile,
      theme.fontSize.heading4.desktop,
      theme
    )};
    line-height: ${theme.lineHeights.sm};
  `}
`;

export const Description = styled(Text)`
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
  margin-bottom: 3.5rem;
`;

export const Container = styled.div`
  ${({ theme }) => `
    padding: ${theme.padding.md} 0;
    width: 100%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    max-width: 32rem;
    height: 100%;
    margin-left: 5rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: 1.5rem;
      margin-left: 0;
    }
  `}
`;

export const DescriptionCategory = styled.p`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    text-transform: uppercase;
    line-height: ${theme.lineHeights.xs};
    color: ${theme.colors.muted};
    margin-bottom: 1rem;
  `}
`;

export const RichTextContainer = styled.div`
  ${({ theme }) => css`
    margin: ${theme.padding.md} 0;
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.fontSize.body.desktop};
    color: ${theme.colors.dark};
    line-height: 1.6875rem;

    p + p {
      margin-top: 1.5rem;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    a,
    a:visited {
      color: ${theme.colors.secondary};
      ${underlineTransition}
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${theme.fontFamily.display};
      color: ${theme.colors.primary};
      margin: 3rem 0 1.5rem;
      line-height: ${theme.lineHeights.sm};
      font-weight: bold;
    }

    h1 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading1.mobile,
        theme.fontSize.heading1.desktop,
        theme
      )}
    }

    h2 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading2.mobile,
        theme.fontSize.heading2.desktop,
        theme
      )}
    }

    h3 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading3.mobile,
        theme.fontSize.heading3.desktop,
        theme
      )}
    }

    h4 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading4.mobile,
        theme.fontSize.heading4.desktop,
        theme
      )}
    }

    h5 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading5.mobile,
        theme.fontSize.heading5.desktop,
        theme
      )}
    }

    h6 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading6.mobile,
        theme.fontSize.heading6.desktop,
        theme
      )}
    }

    ul,
    ol {
      margin: 2rem 0;
      padding-left: 1.5rem;
    }
    ul {
      li {
        margin-top: 1rem;
        list-style-type: disc;
      }
    }

    ol {
      li {
        margin-top: 1rem;
        list-style-type: decimal;
      }
    }
  `}
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

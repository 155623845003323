import React, { useState, useRef } from 'react';
import Collapsible from 'react-collapsible';
import { reposition } from 'nanopop';
import useOnClickOutside from '@hooks/use-on-click-outside';
import generateId from '@helpers/generate-id';
import {
  DropdownContainer,
  StyledDropDown,
  Button,
  MobileButton,
  Links,
  AccentLink,
  Title,
  StyledCaret,
  Triangle,
  GroupCaret,
  DropDownColumn,
  LinkContainer,
  Description,
  MobileMenu,
  Badge,
} from './dropdown.styled';

export interface DropdownProps {
  data: {
    primary: {
      group_title: {
        text: string;
      };
    };
    items: {
      link: {
        url: string;
        document: {
          uid: string;
        };
      };
      link_label: string;
      description: string;
      badge: string;
    }[];
  }[];
  title?: string;
  layoutType: 'dark' | 'light';
}
const dropdownIdGenerator = generateId();

const Dropdown = ({ title, data, layoutType }: DropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    if (ref.current && dropdownRef.current) {
      reposition(ref.current, dropdownRef.current, {
        position: 'bottom-middle',
        margin: 14,
      });
    }

    setIsOpen(!isOpen);
  };

  const CollapseButton = (isCollapsed = true) => (
    <MobileButton
      onKeyPress={toggle}
      onClick={toggle}
      isVisible={isCollapsed}
      layoutType={layoutType}
    >
      <span>{title}</span> <GroupCaret layoutType={layoutType} />
    </MobileButton>
  );

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <DropdownContainer ref={ref}>
      <Button onKeyPress={toggle} onClick={toggle} isVisible={isOpen} layoutType={layoutType}>
        <span>{title}</span> <StyledCaret layoutType={layoutType} />
      </Button>

      <StyledDropDown isVisible={isOpen} ref={dropdownRef}>
        <Triangle isVisible={isOpen} layoutType={layoutType} />
        {data.map(({ primary, items }) => (
          <DropDownColumn key={dropdownIdGenerator.next().value}>
            {primary.group_title.text && (
              <Title type="title" stringText={primary.group_title.text} />
            )}
            {items.map(({ link, link_label, description, badge }) => {
              if (link.document !== null && link.document.uid === 'all-products') {
                return (
                  <LinkContainer key={dropdownIdGenerator.next().value}>
                    <AccentLink href={`/${link.document.uid}`}>{link_label}</AccentLink>
                  </LinkContainer>
                );
              }

              if (link.url !== null && link.url === 'https://www.tripshot.com/resources') {
                return (
                  <LinkContainer key={dropdownIdGenerator.next().value}>
                    <AccentLink href={`${link.url}`}>{link_label}</AccentLink>
                  </LinkContainer>
                );
              }

              return (
                <LinkContainer key={dropdownIdGenerator.next().value}>
                  {link.document !== null ? (
                    <Links href={`/${link.document.uid}`}>
                      {link_label}
                      {badge && <Badge>{badge}</Badge>}
                    </Links>
                  ) : (
                    <Links href={link.url}>{link_label}</Links>
                  )}
                  {description && <Description type="title" stringText={description} />}
                </LinkContainer>
              );
            })}
          </DropDownColumn>
        ))}
      </StyledDropDown>
      <Collapsible
        trigger={CollapseButton(false)}
        transitionTime={250}
        easing="ease-out"
        triggerWhenOpen={CollapseButton(true)}
        open
      >
        <MobileMenu layoutType={layoutType}>
          {data.map(({ primary, items }) => (
            <DropDownColumn key={dropdownIdGenerator.next().value}>
              {primary.group_title.text && (
                <Title type="title" stringText={primary.group_title.text} />
              )}
              {items.map(({ link, link_label, description, badge }) => (
                <LinkContainer key={dropdownIdGenerator.next().value}>
                  {link.document !== null ? (
                    <Links href={`/${link.document.uid}`}>
                      {link_label}
                      {badge && <Badge>{badge}</Badge>}
                    </Links>
                  ) : (
                    <Links href={link.url}>{link_label}</Links>
                  )}
                  {description && <Description type="title" stringText={description} />}
                </LinkContainer>
              ))}
            </DropDownColumn>
          ))}
        </MobileMenu>
      </Collapsible>
    </DropdownContainer>
  );
};

export default Dropdown;

import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Text from '@components/text';
import ContactForm, { OptionType } from '@components/contact-form';
import Section from '@components/section';
import { TitleContainer, Container, StyledWrapper } from './contact-form-section.styled';

export interface ContactFormSectionProps {
  items: OptionType[];
  title?: string;
  description?: RichTextBlock[];
  campaignId: string;
}

const ContactFormSection = ({
  items,
  title,
  description,
  campaignId,
}: ContactFormSectionProps): JSX.Element => {
  return (
    <Section backgroundColor="white" anchorId="contact-form">
      <StyledWrapper>
        <Container>
          <TitleContainer>
            {title && <Text type="title" tag="h2" stringText={title} fontSize="heading2" />}
            {description && <Text type="subheading" richText={description} fontSize="heading4" />}
          </TitleContainer>
          {campaignId !== '' && <ContactForm campaignId={campaignId} why_options={items} />}
        </Container>
      </StyledWrapper>
    </Section>
  );
};

export default ContactFormSection;

import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import StyledTextArea from '../text-area.styled';

const LargeTextArea = styled(StyledTextArea)`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return css`
      flex-grow: 1;
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius, theme)}
      padding: 0.5rem;
    `;
  }}
`;

export default LargeTextArea;

import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import BioCard from '@components/bio-card';
import generateId from '@helpers/generate-id';
import { LeaderGrid, Subheading, StyledText } from './meet-the-leaders.styled';

const MeetTheLeadersIdGenerator = generateId();

export interface MeetTheLeadersItemProps {
  bio?: {
    document?: {
      data?: BioItemProps;
    };
  };
}

export interface BioItemProps {
  isLinked?: boolean;
  photo?: ImageProps;
  name?: {
    text?: string;
  };
  job_title?: {
    richText: RichTextBlock[];
  };
  description?: {
    richText: RichTextBlock[];
  };
}

interface MeetTheLeadersProps {
  items?: BioItemProps[];
  title?: string;
  positions_title?: string;
  itemsPerRow?: number;
}

export const getBioPath = (BioName = ''): string => BioName.split(' ').join('-').toLowerCase();

const MeetTheLeaders = ({
  items = [],
  title,
  positions_title,
  itemsPerRow,
}: MeetTheLeadersProps): JSX.Element => {
  if (!title && !positions_title && !items) {
    return <></>;
  }

  return (
    <Section backgroundColor="white">
      <Wrapper>
        {title && <StyledText fontSize="heading2" type="title" stringText={title} />}
        {positions_title && (
          <Subheading fontSize="heading3" type="title" stringText={positions_title} />
        )}
        <LeaderGrid itemsPerRow={itemsPerRow}>
          {items?.map(({ photo, name, job_title, isLinked }) => {
            if (!photo || !name || !job_title) {
              return null;
            }
            return (
              <BioCard
                key={MeetTheLeadersIdGenerator.next().value}
                photo={photo}
                name={name}
                job_title={job_title}
                className="bio-card"
                isLinked={isLinked}
              />
            );
          })}
        </LeaderGrid>
      </Wrapper>
    </Section>
  );
};

export default MeetTheLeaders;

import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';

export const Title = styled(Text)`
  ${({ theme }) => css`
    margin: 0;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    font-family: ${theme.fontFamily.display};
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights.base};
    color: ${theme.colors.primary};
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    transition: background-size 0.25s;
    text-decoration: none;
    display: inline;
  `}
`;

export const BioImage = styled(Image)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.25s ease-in-out;
`;

export const Meta = styled.div`
  width: 100%;
  padding-top: 1rem;
`;

export const ImageContainer = styled.div`
  overflow: hidden;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    background-color: ${theme.colors.white};
  `}
`;

export const SubHeading = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.muted};
    font-family: ${theme.fontFamily.additional};
    font-weight: ${theme.fontWeights.normal};
    ${generateScales('font-size', '0.9375rem', '1rem', theme)}
    line-height: ${theme.lineHeights.base};
    margin-top: 0.25rem;
    display: block;
  `}
`;

export const CardLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    text-decoration: none;
    width: 100%;
    cursor: pointer;

    &:hover {
      ${BioImage} {
        transform: scale(1.0625);
      }

      ${Title} {
        background-size: 100% 2px;
      }
    }
  `}
`;

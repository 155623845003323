import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Collapsible from 'react-collapsible';
import Wrapper from '@components/wrapper.styled';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import {
  Container,
  ContentContainer,
  StyledImage,
  Title,
  Description,
  StyledEyebrow,
  AccordionItem,
  Content,
  StyledArrow,
  AccordionButton,
  RightDescription,
  CollapsibleContent,
} from './mission.styled';

interface MissionProps {
  eyebrow?: string;
  image?: ImageProps;
  title?: string;
  rightText?: RichTextBlock[];
  leftText?: RichTextBlock[];
}

const renderAccordionItem = (stringText: string, isRotated: boolean): JSX.Element => (
  <AccordionItem>
    <Content>
      <StyledArrow width={36} height={36} stroke="white" fill="secondary" isRotated={isRotated} />
      <AccordionButton type="title" fontSize="heading4" stringText={stringText} />
    </Content>
  </AccordionItem>
);

const Mission = ({ eyebrow, title, rightText, leftText, image }: MissionProps): JSX.Element => {
  if (!title && !eyebrow && !rightText && !leftText && !image) {
    return <></>;
  }

  const renderCollapsibleText = (): JSX.Element => (
    <CollapsibleContent>
      {/* @ts-expect-error Server Component */}
      <Collapsible
        trigger={renderAccordionItem('Continue Reading', false)}
        triggerWhenOpen={renderAccordionItem('Less Reading', true)}
      >
        <Description type="description" richText={leftText} />
      </Collapsible>
    </CollapsibleContent>
  );

  return (
    <Section backgroundColor="light">
      <Wrapper>
        <Container>
          {(image?.gatsbyImageData || image?.url) && (
            <StyledImage
              url={image.url}
              alt={image.alt}
              gatsbyImageData={image.gatsbyImageData}
              objectFit="contain"
            />
          )}
          <div>
            {eyebrow && <StyledEyebrow type="eyebrow" stringText={eyebrow} />}
            {title && <Title type="title" tag="h2" fontSize="heading3" stringText={title} />}
            {!!leftText?.length && (
              <ContentContainer>
                <Description type="description" richText={leftText} />
                {!!rightText?.length && rightText[0].text && (
                  <>
                    <RightDescription type="description" richText={rightText} />
                    {renderCollapsibleText()}
                  </>
                )}
              </ContentContainer>
            )}
          </div>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Mission;

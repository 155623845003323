import styled from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';

export const Container = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    margin-bottom: 7rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => `
    width: 100%;
    margin: 0 0 6.25rem 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin: 0 0 2rem 0;
    }
  `}
`;

export const DescriptionContainer = styled.div`
  width: 100%;
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => `
    margin-bottom:  0.5rem;
    ${generateScales(
      'font-size',
      theme.fontSize.heading6.mobile,
      theme.fontSize.heading5.mobile,
      theme
    )};
    color: ${theme.colors.black};
  `}
`;

export const StyledDescription = styled(Text)`
  ${({ theme }) => `
    font-size: 1rem;
    line-height: ${theme.lineHeights.lg};
    color: ${theme.colors.muted};
  `}
`;

export const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 3.25rem;
  height: 3.25rem;
`;

export const StyledImage = styled(Image)`
  width: 2.5rem;
  height: 2.5rem;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    max-width: 47.5rem;
    height: 100%;
    margin-left: 1rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
      margin: 0;
    }
  `}
`;

export const Title = styled(Text)`
  width: 42rem;
  ${({ theme }) => `
    ${generateScales('font-size', '1.5rem', '2rem', theme)};
    line-height: ${theme.lineHeights.xl};
    color: ${theme.colors.black};
    padding-bottom: ${theme.padding.lg};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center;
      width: 100%;
    }
  `};
`;

export const StyledSubheading = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.black};
    font-size: ${theme.fontSize.heading4.desktop};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center;
      font-size: ${theme.fontSize.heading5.desktop};
      line-height: 4rem;
    }
  `}
`;

import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { RichTextBlock } from 'prismic-reactjs';
import generateId from '@helpers/generate-id';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import { Wrapper } from '@components/wrapper.styled';
import Section from '@components/section';
import slugify from '@helpers/slugify';
import {
  TitleContainer,
  OpenedContent,
  OpenedSubheading,
  AccordionItem,
  List,
  ListItem,
  StyledImage,
  StyledVector,
  Content,
  TextAndIcon,
  Label,
  StyledText,
} from './listing.shared.styled';

const idGenerator = generateId();

interface ItemProps {
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  image?: ImageProps;
}

export interface ListingProps {
  title?: string;
  items: ItemProps[];
}

const Listing = ({ title, items }: ListingProps): JSX.Element => {
  const [opened, setOpened] = useState<null | number>(null);

  const renderTitleContent = (item: ItemProps, isOpened: boolean): JSX.Element => (
    <>
      <Content>
        <StyledImage
          url={item.image?.url}
          gatsbyImageData={item.image?.gatsbyImageData}
          objectFit="contain"
          alt={item.image?.alt || ''}
        />
        <StyledText
          type="title"
          stringText={item.title?.text}
          fontSize="heading6"
          tag="h3"
          fontColor="dark"
          role="heading"
        />
      </Content>
      <TextAndIcon>
        <Label>Read More</Label>
        <StyledVector isRotated={isOpened} />
      </TextAndIcon>
    </>
  );

  return (
    <Section backgroundColor="white">
      <Wrapper>
        <TitleContainer>
          <Text tag="h2" type="title" stringText={title} fontSize="heading4" />
        </TitleContainer>
        <List>
          {items.map((item, i) => {
            const itemId = idGenerator.next().value;
            const prefix = slugify(title || '');

            return (
              <ListItem>
                <Collapsible
                  trigger={<AccordionItem>{renderTitleContent(item, false)}</AccordionItem>}
                  triggerElementProps={{
                    'aria-label': 'Open section',
                    tabIndex: 0,
                    role: 'button',
                    id: `${prefix}-trigger-${itemId}`,
                  }}
                  open={i === opened}
                  transitionTime={250}
                  easing="ease-out"
                  triggerWhenOpen={<OpenedContent>{renderTitleContent(item, true)}</OpenedContent>}
                  onOpening={() => setOpened(i)}
                >
                  <OpenedSubheading>
                    <Text type="description" richText={item.description?.richText} />
                  </OpenedSubheading>
                </Collapsible>
              </ListItem>
            );
          })}
        </List>
      </Wrapper>
    </Section>
  );
};

export default Listing;

import styled from 'styled-components';
import Text from '@components/text';
import LinkComponent from '@components/link';

export const Container = styled.div`
  ${({ theme }) => `
    margin: 0 auto;
    padding: ${theme.padding.cards.lg};
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.primaryLight};
    border-radius: 0.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: ${theme.padding.cards.md};
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: ${theme.padding.cards.sm};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: ${theme.padding.cards.sm} 1rem;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => `
    width: 100%;
    text-align: center;
    padding: 0 ${theme.padding.cards.md};

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const LinkButton = styled(LinkComponent)`
  margin: 2rem 0 auto;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => `
    display: block;
    margin-bottom: ${theme.spacing.md}
  `}
`;

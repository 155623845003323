import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Wrapper from '@components/wrapper.styled';
import Image, { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import NewsletterModal from '@components/newsletter-modal';
import getLinkConfig from '@helpers/get-link-config';
import { PrimaryProps } from '../module/index';
import { Item } from '../module/item-props.type';
import {
  StyledFooter,
  SocialWrapper,
  Shape,
  TopContainer,
  LogoContainer,
  Logo,
  LogoDescription,
  LinksGrid,
  LinksGridColumn,
  ItemTitle,
  ItemLink,
  LinkList,
  LinkListItem,
  ExternalItemLink,
  ExternalLink,
  BottomContainer,
  StyledSpan,
  BottomLinksContainer,
  BottomLinks,
  StyledLink,
  SocialMedia,
  MetaContainer,
} from './footer.styled';

const columnIdGenerator = generateId();
const navListItemIdGenerator = generateId();
const metaLinkIdGenerator = generateId();
const socialLinkIdGenerator = generateId();

interface FooterProps {
  offsetAmount: number;
}

interface LinksType {
  link_label: string;
  link: {
    url: string;
    uid: string;
    target: string;
  };
}

interface SocialMediaType {
  image: ImageProps;
  link: {
    uid: string;
    url: string;
    target: string;
  };
}

interface FooterPageInterface {
  prismicGeneralConfig: {
    data: {
      legal_brand_name?: string;
      logo?: {
        alt: string;
        url: string;
        uid: string;
      };
      links: LinksType[];
      social_media: SocialMediaType[];
      body: {
        slice_type: string;
        primary: PrimaryProps;
        items: Item[];
      }[];
    };
  };
}

const query = graphql`
  query FooterQuery {
    prismicGeneralConfig {
      data {
        legal_brand_name
        logo {
          alt
          url
        }
        links {
          link {
            uid
            url
            target
          }
          link_label
        }
        social_media {
          image {
            url
            alt
          }
          link {
            url
            target
          }
        }
        body {
          ... on PrismicGeneralConfigDataBodyLinks {
            slice_type
            primary {
              title {
                text
              }
            }
            items {
              link {
                uid
                url
              }
              link_label
            }
          }
        }
      }
    }
  }
`;

const Footer = ({ offsetAmount }: FooterProps): JSX.Element => {
  const {
    prismicGeneralConfig: {
      data: { legal_brand_name, logo, links, social_media, body },
    },
  }: FooterPageInterface = useStaticQuery(query);
  const currentYear = new Date().getFullYear();
  const copyText = `© ${currentYear} ${legal_brand_name || 'TripShot Inc'} All rights reserved.`;

  return (
    <StyledFooter>
      <Wrapper>
        {logo?.url && (
          <TopContainer>
            <LinksGrid>
              {body.map(({ primary, items }) => {
                const columnId = columnIdGenerator.next().value;
                const columnTitleId = `link-grid-column-${columnId}`;
                return (
                  <LinksGridColumn key={columnId}>
                    <ItemTitle
                      id={columnTitleId}
                      type="title"
                      tag="h4"
                      fontColor="primary"
                      fontSize="body"
                      stringText={primary.title?.text}
                    />
                    <LinkList
                      aria-labelledby={columnTitleId}
                      aria-label={`${primary.title?.text || 'Section'} Navigation`}
                    >
                      {items.map(({ link, link_label }) => {
                        const linkConfig = getLinkConfig(link);
                        if (!linkConfig.url || !link_label) {
                          return null;
                        }

                        return (
                          <LinkListItem key={navListItemIdGenerator.next().value}>
                            {linkConfig.isExternal ? (
                              <ExternalItemLink href={linkConfig.url}>
                                {link_label}
                              </ExternalItemLink>
                            ) : (
                              <ItemLink to={linkConfig.url}>{link_label}</ItemLink>
                            )}
                          </LinkListItem>
                        );
                      })}
                    </LinkList>
                  </LinksGridColumn>
                );
              })}
            </LinksGrid>
            <MetaContainer>
              <LogoContainer>
                {logo?.url && <Logo imageUrl={logo.url} />}
                <LogoDescription>
                  TripShot is a simple, powerful mobility operating system that manages, tracks, and
                  optimizes fleets and transportation services.
                </LogoDescription>
              </LogoContainer>
              <NewsletterModal offsetAmount={offsetAmount} />
            </MetaContainer>
          </TopContainer>
        )}
      </Wrapper>
      <Wrapper style={{ zIndex: 0 }}>
        <BottomContainer>
          <StyledSpan>{copyText}</StyledSpan>
          <BottomLinksContainer>
            <BottomLinks>
              {links.map(({ link_label, link }) => {
                const linkConfig = getLinkConfig(link);

                return (
                  <React.Fragment key={metaLinkIdGenerator.next().value}>
                    {linkConfig.isExternal ? (
                      <ExternalLink href={linkConfig.url}>{link_label}</ExternalLink>
                    ) : (
                      <StyledLink to={linkConfig.url}>{link_label}</StyledLink>
                    )}
                  </React.Fragment>
                );
              })}
            </BottomLinks>
            <SocialWrapper>
              {social_media.map(({ image, link }) => {
                if (!image?.url || !link?.url) {
                  return null;
                }

                return (
                  <SocialMedia
                    href={link?.url}
                    target={link?.target}
                    rel="noreferrer"
                    key={socialLinkIdGenerator.next().value}
                    title={
                      image?.alt !== undefined
                        ? `Follow us on ${image?.alt}`
                        : 'Follow us on social'
                    }
                  >
                    <Image url={image?.url} alt={image?.alt} />
                  </SocialMedia>
                );
              })}
            </SocialWrapper>
          </BottomLinksContainer>
        </BottomContainer>
        <Shape />
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;

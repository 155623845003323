import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';
import Arrow from '@components/svg-icons/arrow';

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    font-family: ${theme.fontFamily.additional};
    font-size: ${theme.fontSize.body.desktop};
    color: ${theme.colors.dark};

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: block;
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 11.44rem;
    width: 100%;
    margin-right: 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      margin: 0 auto;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
    }
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    max-height: 16rem;

    picture,
    img,
    div {
      object-position: left top;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 2.5rem;
      max-height: 12rem;

      picture,
      img,
      div {
        object-position: left center;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin-bottom: 2rem;
    }
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => css`
    display: block;
    max-width: 40rem;
    margin: 0;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }

    + ${ContentContainer} {
      ${generateScales('margin-top', '2rem', '4rem', theme)}
    }
  `}
`;

export const StyledEyebrow = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.secondary};
  `}
`;

export const Description = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.additional};
    color: ${theme.colors.primary};
    font-weight: normal;
    line-height: ${theme.lineHeights.lg};
    ${generateScales(
      'font-size',
      theme.fontSize.small.mobile,
      theme.fontSize.subheading.desktop,
      theme
    )}
    columns: 2;
    column-gap: 3rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      columns: 1;
    }

    p + p {
      ${generateScales('margin-top', '1.125rem', '1.375rem', theme)}
    }
  `}
`;

export const Content = styled.div`
  max-width: 12.94rem;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const AccordionItem = styled.div`
  justify-content: space-between;
  padding: 1rem 0 0;
  font-weight: 700;
  line-height: 1.25rem;
  cursor: pointer;
  display: none;

  ${({ theme }) => css`
    color: ${theme.colors.primary};
    ${generateScales('font-size', '1rem', '1.125rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: block;
    }
  `}
`;

export const StyledArrow = styled(Arrow)<{ isRotated: boolean }>`
  transform: rotate(${({ isRotated }) => (isRotated ? -90 : 90)}deg);
  margin-right: 1rem;
`;

export const AccordionButton = styled(Text)`
  ${({ theme }) => `
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)};
    margin: 0;
    color: ${theme.colors.secondary};

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }  
  `}
`;

export const CollapsibleContent = styled.div`
  ${({ theme }) => `
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: block;
    }  
  `}
`;

export const RightDescription = styled(Description)`
  ${({ theme }) => `
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }  
  `}
`;

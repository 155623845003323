import styled from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';
import { generateScales } from '@helpers/generate-media-queries';

export const Container = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 30.63rem;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media (max-width: ${theme.breakpoints.md}) {
      text-align: center;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => `
    z-index: ${theme.zIndex.layeredContent};
    max-width: 35.25rem;
    width: 100%;
    padding: 0 ${theme.padding.cards.md};

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0 0 2rem 0;
      margin: 0 auto;
    }
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    ${generateScales('font-size', '1.0625rem', '2.5rem', theme)}
    line-height: ${theme.lineHeights.sm};
    color: ${theme.colors.black};
  `}
`;

export const StyledSubheading = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.black};
    line-height: ${theme.lineHeights.lg};
    ${generateScales('font-size', '1', '1.5', theme)}
    margin-top: 2rem;   
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => `
    z-index: ${theme.zIndex.layeredGraphic};
    position: absolute;
    width: 100%;
    opacity: 0.7;
    height: 30.625rem;
  `}
`;

import React, { ChangeEvent } from 'react';
import SmallTextArea from './text-area-small/text-area-small.styled';
import LargeTextArea from './text-area-large/text-area-large.styled';

export interface TextAreaProps {
  size?: 'small';
  placeholder?: string;
  onChange?: (value: string) => void;
  className?: string;
  value?: string | number;
  name?: string;
}

const TextArea = ({
  placeholder,
  size,
  className = '',
  onChange,
  value = '',
  name,
}: TextAreaProps): JSX.Element | null => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) {
      return null;
    }
    return onChange(e.target.value);
  };
  switch (size) {
    case 'small':
      return (
        <SmallTextArea
          placeholder={placeholder}
          onChange={handleChange}
          className={className}
          value={value}
          name={name}
          rows={3}
        />
      );
    default:
      return (
        <LargeTextArea
          placeholder={placeholder}
          onChange={handleChange}
          className={className}
          value={value}
          name={name}
          rows={4}
        />
      );
  }
};

export default TextArea;

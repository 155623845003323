import styled, { css } from 'styled-components';
import { generateScales, generateScalingQueries } from '@helpers/generate-media-queries';
import { wrapperPadding } from '@components/wrapper.styled';

export const ContentBlock = styled.div`
  ${({ theme }) => css`
    text-align: center;
    max-width: 52rem;
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;

export const Subheading = styled.p`
  ${({ theme }) => {
    return css`
      line-height: 1.375;
      ${generateScales(
        'font-size',
        theme.fontSize.subheading.mobile,
        theme.fontSize.subheading.desktop,
        theme
      )}
      color: ${theme.colors.primary};
      margin: 0;
      ${generateScalingQueries('margin-bottom', '3.5rem', theme)}
    `;
  }}
`;

export interface ScrollerProps {
  $isXScrolled: boolean;
  $isYScrolled: boolean;
  $rowHeaderWidth: number;
}

export const ShadowWrapper = styled.div<ScrollerProps>`
  ${({ theme, $isYScrolled, $isXScrolled, $rowHeaderWidth }) => css`
    position: relative;
    border-top-right-radius: ${theme.borderRadius.normal};
    border-bottom-right-radius: ${theme.borderRadius.normal};
    overflow: hidden;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      border-bottom-right-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
      width: calc(100vw - (${wrapperPadding} * 4));
      margin-left: calc(((100% - 100vw) / 2) + (${wrapperPadding} * 2));

      &:: before {
        font-family: ${theme.fontFamily.display};
        ${generateScales(
          'font-size',
          theme.fontSize.heading6.mobile,
          theme.fontSize.heading6.desktop,
          theme
        )}
        content: '→';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 1.5rem 1.25rem 1.5rem 5rem;
        color: ${theme.colors.lightPurple};
        z-index: 11;
        transition: all 0.25s ease-in-out;
        pointer-events: none;
        background: linear-gradient(90deg, rgba(243, 232, 218, 0) 0%, rgba(243, 232, 218, 1) 50%);
      }

      ${$isXScrolled &&
      css`
        &::before {
          opacity: 0;
        }
      `}
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0;

      &:: after {
        font-family: ${theme.fontFamily.display};
        ${generateScales(
          'font-size',
          theme.fontSize.heading6.mobile,
          theme.fontSize.heading6.desktop,
          theme
        )}
        content: '↓';
        display: block;
        position: absolute;
        bottom: calc(0.75rem + 1px);
        left: 0;
        text-align: center;
        width: ${$rowHeaderWidth}px;
        padding: 1.375rem 1.25rem;
        color: ${theme.colors.lightPurple};
        z-index: 10;
        transition: all 0.25s ease-in-out;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(61, 17, 82, 0) 0%, rgba(61, 17, 82, 1) 50%);
      }

      ${$isYScrolled &&
      css`
        &::after {
          opacity: 0;
        }
      `}

      &::before {
        /* right: calc(0.75rem + 1px); */
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      border-top-right-radius: 0;
      margin-left: 0;
      width: calc(100% + ${wrapperPadding});
    }
  `}
`;

const scrollerBgColor = '#F2E6F5';

export const TableWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      overflow-x: auto;
      overflow-y: hidden;
      border-right: 1px solid #d6a97866;
      border-top-right-radius: ${theme.borderRadius.normal};
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom: 1px solid ${scrollerBgColor};

      scrollbar-color: ${theme.colors.lightPurple} ${scrollerBgColor};
      scrollbar-width: 0.5rem;

      ::-webkit-scrollbar {
        -webkit-appearance: none !important;
      }

      ::-webkit-scrollbar:vertical {
        width: 0.75rem !important;
      }

      ::-webkit-scrollbar:horizontal {
        height: 0.75rem !important;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 0.5rem !important;
        background-color: ${theme.colors.lightPurple} !important;
        border: 0.1875rem solid ${scrollerBgColor} !important;
      }

      ::-webkit-scrollbar-track {
        background-color: ${scrollerBgColor} !important;
        border-bottom-left-radius: 0.375rem !important;
        border-bottom-right-radius: 0.375rem !important;
      }

      ::-webkit-scrollbar-track-piece {
        background-color: ${scrollerBgColor} !important;
        border-radius: 0.375rem !important;
      }

      ::-webkit-scrollbar-corner {
        background-color: ${scrollerBgColor} !important;
      }

      ::-webkit-resizer {
        background-color: ${scrollerBgColor} !important;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      overflow-y: auto;
      max-height: calc(100vh - 8rem);
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0;
      background-color: ${scrollerBgColor};
      border-right-color: ${scrollerBgColor};
    }
  `}
`;

export const CheckMark = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    margin: 0 0.3rem 0.2rem 0.3rem;

    &::after {
      content: '';
      display: block;
      border-right: 2.5px solid ${theme.colors.lightPurple};
      border-bottom: 2.5px solid ${theme.colors.lightPurple};
      ${generateScales('height', '.546875rem', '.626rem', theme)}
      ${generateScales('width', '0.35', '.4rem', theme)}
      transform: rotate(45deg);
      transform-origin: 0 0;
    }
  `}
`;

export const XMark = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    margin: 0 0.5rem 0rem 0rem;
    position: relative;
    height: 1rem;
    width: 0.5rem;

    &::before {
      content: '';
      display: block;
      border-right: 2.5px solid ${theme.colors.primaryLight};
      ${generateScales('height', '.6rem', '.7rem', theme)}
      width: 0;
      transform-origin: 50% 50%;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
    }

    &::after {
      content: '';
      display: block;
      border-right: 2.5px solid ${theme.colors.primaryLight};
      ${generateScales('height', '.6rem', '.7rem', theme)}
      width: 0;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(-45deg);
      transform-origin: 50% 50%;
    }
  `}
`;

export const ComparisonTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  position: relative;
  border: 0;
`;

export const ItemHeaderWrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    background-color: ${theme.colors.brand};
    padding: 1.5rem 1.25rem;
  `}
`;

export const ItemHeader = styled.th`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.display};
    ${generateScales(
      'font-size',
      theme.fontSize.heading6.mobile,
      theme.fontSize.heading6.desktop,
      theme
    )}
    color: ${theme.colors.darkPurple};
    background-color: ${theme.colors.white};
    margin: 0 1rem;
    border-radius: 0;
    border: 0;
    text-align: left;
    padding: 0;

    :not(:first-child) {
      position: -webkit-sticky;
      z-index: 10;
      position: sticky;
      top: 0;
    }

    :first-child {
      background-color: ${theme.colors.white};
      border-radius: 0;
      position: -webkit-sticky;
      z-index: 11;
      position: sticky;
      top: 0;
      left: 0;
    }

    :nth-child(2) ${ItemHeaderWrapper} {
      border-top-left-radius: ${theme.borderRadius.normal};
    }

    :last-child ${ItemHeaderWrapper} {
      border-top-right-radius: ${theme.borderRadius.normal};
      @media only screen and (max-width: ${theme.breakpoints.md}) {
        border-top-right-radius: 0;
      }
    }
  `}
`;

export const ComparisonBody = styled.tbody`
  ${({ theme }) => css`
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    border: 0;
    background-color: ${theme.colors.darkPurple};
  `}
`;

export const RowHeaderWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.backgroundColors.dark};
    color: ${theme.backgroundColors.light};
    height: 100%;
    padding: 1.375rem 1.25rem;
    ${generateScales('padding-left', '1rem', '1.25rem', theme)}
    ${generateScales('padding-right', '1rem', '1.25rem', theme)}
    margin: 0;
    ${generateScales('font-size', '0.9375rem', '1rem', theme)}
    line-height: 125%;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 10;
      border-bottom: 1px solid ${theme.colors.darkPurple};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      left: 1.5rem;
      right: 1.5rem;
      z-index: 11;
      ${generateScales('left', '1rem', '1.25rem', theme)}
      ${generateScales('right', '1rem', '1.25rem', theme)}
      border-bottom: 1px solid #6e4181;
    }
  `}
`;

export const RowItemWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 100%;
    padding: 1.25rem 1.25rem;
    ${generateScales('padding-left', '1rem', '1.25rem', theme)}
    ${generateScales('padding-right', '1rem', '1.25rem', theme)}
    background-color: ${theme.colors.white};
    ${generateScales('font-size', '0.9375rem', '1rem', theme)}
    border-right: 1px solid #d6a97866;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 1.25rem;
      right: 1.25rem;
      ${generateScales('left', '1rem', '1.25rem', theme)}
      ${generateScales('right', '1rem', '1.25rem', theme)}
      z-index: 9;
      border-bottom: 1.25px solid rgba(0, 0, 0, 0.125);
    }
  `}
`;

export const RowItem = styled.td`
  ${({ theme }) => css`
    border: 0;
    padding: 0;
    width: 33.33%;
    line-height: 1.25;
    vertical-align: top;
    background-color: ${theme.backgroundColors.light};
    height: 0px;
    @-moz-document url-prefix() {
      height: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      min-width: 320px;

      &:last-child ${RowItemWrapper} {
        border-right: none;
      }
    }

    * + ul,
    span + div {
      margin-top: 1.25rem;
    }

    ul {
      list-style: none;
      list-style-type: none;
      padding: 0 0 0 0rem;
      margin: 0;

      li {
        & ~ li {
          margin-top: 1.25rem;
        }

        &::marker {
          color: ${theme.colors.lightPurple};
          ${generateScales('font-size', '1rem', '1.25rem', theme)}
        }
      }
    }
  `}
`;

export const ComparisonRow = styled.tr`
  ${({ theme }) => css`
    border: 0;
    @-moz-document url-prefix() {
      height: 100%;
    }

    &:first-child {
      ${RowHeaderWrapper} {
        border-top-left-radius: ${theme.borderRadius.normal};
      }

      ${RowItem} {
        &:last-child {
          border-top-right-radius: ${theme.borderRadius.normal};

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            border-top-right-radius: 0.375rem;
          }
        }
      }
    }

    &:last-child {
      overflow: hidden;

      ${RowHeaderWrapper} {
        border-bottom: none;
        border-bottom-left-radius: ${theme.borderRadius.normal};
        padding: 1rem 1rem;

        @media only screen and (max-width: ${theme.breakpoints.xl}) {
          border-bottom-left-radius: 0;
        }
      }

      ${RowItem} {
        &:last-child ${RowItemWrapper} {
          border-bottom-right-radius: ${theme.borderRadius.normal};

          @media only screen and (max-width: ${theme.breakpoints.xl}) {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          border-bottom-right-radius: ${theme.borderRadius.normal};

          @media only screen and (max-width: ${theme.breakpoints.xl}) {
            border-bottom-right-radius: 0;
          }
        }
      }

      ${RowItemWrapper} {
        padding: 1.5rem 1rem;
        border-bottom: 1.25px solid #d6a97866;

        &::after {
          display: none;
        }
      }
    }
  `}
`;

export const RowHeader = styled.th`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.darkPurple};
    border: 0;
    height: 1px;
    vertical-align: top;
    z-index: 10;
    position: sticky;
    left: 0;
    text-align: left;
    padding: 0;
    white-space: nowrap;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      white-space: unset;
    }
  `}
`;

export const HeaderList = styled.thead`
  border: 0;

  ${ItemHeader}:not(:last-child) ${ItemHeaderWrapper} {
    border-right: 1px solid #d6a97866;
  }
`;

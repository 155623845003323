import styled, { css, keyframes } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';
import { underlineTransition } from '@components/link/link.styled';
import Text from '@components/text';
import { hexToRgb } from '@helpers/hex-to-rgb';

export const StyledImage = styled(Image)`
  height: auto;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
`;

export const StyledLink = styled.a`
  ${({ theme }) => {
    const { mobile, desktop } = theme.fontSize.body;
    return css`
      text-decoration: none;
      color: ${theme.colors.secondary};
      font-weight: 600;
      ${generateScales('font-size', mobile, desktop, theme)}
      ${underlineTransition}

      ::after {
        content: '→';
        margin-left: 0.25rem;
      }
    `;
  }}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    text-align: center;
    ${generateScales('margin-bottom', '2rem', '4rem', theme)}
  `}
`;

const gradientKeyframes = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const fadeInKeyframes = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

export const GradientLoader = css`
  ${({ theme }) => {
    const rgbVals = hexToRgb(theme?.backgroundColors.light);

    return css`
      position: relative;

      ::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: ${gradientKeyframes} 3s linear infinite;
        background: linear-gradient(
          45deg,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 0%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 2.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 15%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 35%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 47.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 52.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 65%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 85%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 97.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 100%
        );
        background-size: 400% 400%;
      }
    `;
  }}
`;

export const DummyWrapper = styled.div`
  ${GradientLoader}
  opacity: 0.25;
`;

export const DummyImageContainer = styled.div`
  padding-top: 56.25%;
  background: ${({ theme }) => theme.colors.muted};
  border-radius: 0.5rem;
`;

export const DummyCopy = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  ${({ theme }) => {
    const desktopH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.desktop);
    const mobileH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.mobile);
    return css`
      background: ${theme.colors.muted};
      ${generateScales('border-radius', `${desktopH / 4}rem`, `${mobileH / 4}rem`, theme)}
      ${generateScales('height', `${desktopH}rem`, `${mobileH}rem`, theme)}
    `;
  }}
`;

export const DummyTitle = styled.div`
  width: ${`${Math.random() * (100 - 75) + 75}%`};
  margin-top: 1.5rem;

  ${({ theme }) => {
    const desktopH =
      parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.heading5.desktop);
    const mobileH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.heading5.mobile);
    return css`
      background: ${theme.colors.muted};
      ${generateScales('border-radius', `${desktopH / 4}rem`, `${mobileH / 4}rem`, theme)}
      ${generateScales('height', `${desktopH}rem`, `${mobileH}rem`, theme)}
      ${generateScales('margin-bottom', '1.25rem', '1.5rem', theme)}
    `;
  }}
`;

export const PostWrapper = styled.div`
  animation: ${fadeInKeyframes} 0.75s ease-in-out;
`;

export const PostGrid = styled.div<{ isLoaded: boolean }>`
  ${({ theme, isLoaded = false }) => css`
    display: grid;
    text-align: left;
    row-gap: 2.5rem;
    column-gap: 2.5rem;
    ${generateScales('row-gap', '2.5rem', '5rem', theme)}
    ${generateScales('column-gap', '2.5rem', '5rem', theme)}
    grid-template-columns: repeat(3,1fr);
    transition: all ${theme.transitions.normal};
    ${isLoaded
      ? css`
          ${DummyWrapper} {
            display: none;
          }
        `
      : css`
          ${PostWrapper} {
            display: none;
          }
        `}

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`;

export const DummyLink = styled.div`
  width: ${`${Math.random() * (50 - 25) + 25}%`};

  ${({ theme }) => {
    const desktopH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.desktop);
    const mobileH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.mobile);
    return css`
      background: ${theme.colors.muted};
      ${generateScales('border-radius', `${desktopH / 4}rem`, `${mobileH / 4}rem`, theme)}
      ${generateScales('height', `${desktopH}rem`, `${mobileH}rem`, theme)}
    `;
  }}
`;

import React, { useState, useRef } from 'react';
import useOnClickOutside from '@hooks/use-on-click-outside';
import generateId from '@helpers/generate-id';
import {
  DropdownContainer,
  Button,
  Text,
  ListItem,
  DropDownListContainer,
  DropDownList,
} from './select.styled';

interface OptionType {
  option: string;
}

export interface SelectProps {
  items: OptionType[];
  onSelect: (value: string) => void;
}
const selectItemIdGenerator = generateId();

const Select = ({ items, onSelect }: SelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));
  const [selectedOption, setSelectedOption] = useState<string>();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: string) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    onSelect(value);
  };

  return (
    <DropdownContainer ref={ref}>
      <Button
        type="button"
        onKeyPress={toggle}
        onClick={toggle}
        isVisible={isOpen}
        isSet={selectedOption !== undefined}
      >
        {selectedOption || '--Select Reason--'}
      </Button>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {items.map((item) => (
              <ListItem
                onClick={onOptionClicked(item.option)}
                key={selectItemIdGenerator.next().value}
              >
                <Text>{item.option}</Text>
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropdownContainer>
  );
};

export default Select;

import styled, { css } from 'styled-components';
import Text from '@components/text';
import { ImageProps } from '@components/image';
import { generateScales } from '@helpers/generate-media-queries';

export const ImageBackground = styled.div<{ image?: ImageProps }>`
  ${({ theme, image }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 5rem;

    ${image?.url !== undefined &&
    image.url !== null &&
    image.url !== '' &&
    css`
      background-image: url(${image.url});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    `}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      row-gap: 2rem;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      row-gap: 3.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
      row-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      row-gap: 2rem;
    }
  `}
`;

export const TextContent = styled.div`
  ${({ theme }) => css`
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: block;
      row-gap: 2rem;
      display: flex;
      flex-direction: column;
    }
  `}
`;

export const NumberContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.lightGreen};
    border-radius: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 2;
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeights.bold};
    ${generateScales(
      'font-size',
      theme.fontSize.heading5.mobile,
      theme.fontSize.heading5.desktop,
      theme
    )}
  `}
`;

export const NumberBlock = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  column-gap: 1rem;
  max-width: 24rem;
  align-items: center;
`;

export const Subheading = styled(Text)`
  ${({ theme }) => css`
    display: block;
    text-align: center;
    ${generateScales('margin-bottom', '2rem', '4rem', theme)}
  `}
`;

export const Title = styled(Text)`
  text-align: center;
`;

export const StyledText = styled(Text)`
  justify-content: center;
  align-items: center;
  display: inline-flex;
  text-align: left;
  z-index: 2;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
    }
  `}
`;

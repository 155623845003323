import styled, { css } from 'styled-components';
import Section from '@components/section';
import Text from '@components/text';
import { defaultTheme } from '@styles/theme.styled';

export const Container = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4rem;
    max-width: 35rem;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 3rem;
      align-items: flex-start;
      text-align: left;
    }
  `}
`;

export const AppText = styled(Text)`
  font-size: 0.875rem;
  opacity: 0.5;
`;

interface SectionProps {
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

export const AppSection = styled(Section)<SectionProps>`
  ${({ theme, backgroundColor }) => css`
    ${backgroundColor === ('dark' || 'primary') &&
    css`
      ${AppText} {
        color: ${theme.colors.white};
      }
    `}
  `}
`;

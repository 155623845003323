import React, { useState, useRef, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { DefaultTheme } from 'styled-components';
import renderNavbarLinks, { NavbarLinksType } from '@helpers/get-navbar-links';
import renderMobileNavbarLinks from '@helpers/get-mobile-navbar-links';
import { PositionsItemType } from '@components/positions';
import Wrapper from '@components/wrapper.styled';
import { debounce } from '@helpers/debounce';
import {
  HeaderWrapper,
  HeaderBgColor,
  StyledHeader,
  Content,
  Menu,
  Logo,
  MainActionsWrapper,
  MobileContactButton,
} from './header.styled';
import { BurgerButton, Line, MobileMenu, MobileContent } from './mobile-header.styled';

interface ItemsType {
  items: PositionsItemType[];
}

interface HeaderQueryInterface {
  prismicGeneralConfig: {
    data: {
      logo: {
        alt?: string;
        url: string;
        target: string;
      };
      navbar_links: NavbarLinksType[];
    };
  };
  prismicPage: {
    data: {
      body: ItemsType[];
    };
  };
}

export const query = graphql`
  query HeaderQuery {
    prismicGeneralConfig {
      data {
        logo {
          url
          alt
        }
        navbar_links {
          link_label
          link_url {
            url
            target
            document {
              ... on PrismicPage {
                uid
              }
              ... on PrismicContact {
                uid
              }
            }
          }
          dropdown_menu {
            document {
              ... on PrismicDropdownMenu {
                uid
                data {
                  title {
                    text
                  }
                  body {
                    ... on PrismicDropdownMenuDataBodyLinks {
                      primary {
                        group_title {
                          text
                        }
                      }
                      items {
                        link {
                          url
                          document {
                            ... on PrismicPage {
                              uid
                            }
                          }
                        }
                        link_label
                        description
                        badge
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicPage(uid: { eq: "careers" }) {
      data {
        body {
          ... on PrismicPageDataBodyPositions {
            items {
              position {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

interface HeaderProps {
  layoutType?: 'dark' | 'light';
  theme: DefaultTheme;
}

const Header = ({ layoutType = 'dark', theme }: HeaderProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [show, setShow] = useState(false);
  const headerRef = useRef<HTMLHeadingElement>(null);

  const {
    prismicGeneralConfig: {
      data: { logo, navbar_links },
    },
    prismicPage: {
      data: { body },
    },
  }: HeaderQueryInterface = useStaticQuery(query);

  const headerHeight = headerRef?.current?.offsetHeight;
  const positions = body
    .reduce((acc: PositionsItemType[], current) => acc.concat(current.items), [])
    .filter((position) => position);

  useEffect(() => {
    const navbarControl = () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    if (typeof window !== `undefined`) {
      window.addEventListener(`scroll`, debounce(navbarControl, 250));
    }

    return () => window.removeEventListener(`scroll`, navbarControl);
  }, []);

  return (
    <>
      <StyledHeader $isActive={isMenuVisible} layoutType={layoutType} $visible={show}>
        <HeaderWrapper>
          <Content>
            {logo.url && (
              <a href="/" id="logo-navbar-link" aria-label="Navigate to the Homepage">
                <Logo imageUrl={logo.url} layoutType={layoutType} />
              </a>
            )}
            <Menu>{renderNavbarLinks(navbar_links, layoutType, positions.length)}</Menu>
            <MainActionsWrapper>
              <MobileContactButton to="/contact" $isActive={false} id="mobile-contact-navbar-link">
                Contact
              </MobileContactButton>
              <BurgerButton
                onClick={() => setIsMenuVisible(!isMenuVisible)}
                isActive={isMenuVisible}
                layoutType={layoutType}
              >
                <Line />
                <Line />
                <Line />
              </BurgerButton>
            </MainActionsWrapper>
          </Content>
        </HeaderWrapper>
        <HeaderBgColor
          ref={headerRef}
          $backgroundColor={layoutType === 'dark' ? theme.colors.secondary : theme.colors.white}
          $visible={show}
        />
      </StyledHeader>

      <MobileContent isVisible={isMenuVisible} headerHeight={headerHeight} layoutType={layoutType}>
        <Wrapper>
          <MobileMenu>
            {renderMobileNavbarLinks(navbar_links, layoutType, positions.length)}
          </MobileMenu>
        </Wrapper>
      </MobileContent>
    </>
  );
};

export default Header;

import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import DownloadButtons, { ItemAppDownload } from '@components/download-buttons';
import Wrapper from '@components/wrapper.styled';
import { defaultTheme } from '@styles/theme.styled';
import { AppText, AppSection, Container } from './app-download.styled';

interface AppDownloadProps {
  description?: RichTextBlock[];
  items?: ItemAppDownload[];
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

const AppDownload = ({ description, items, backgroundColor = 'transparent' }: AppDownloadProps) => (
  <AppSection backgroundColor={backgroundColor}>
    <Wrapper>
      <Container>
        {!!items?.length && <DownloadButtons items={items} />}
        {!!description?.length && <AppText type="description" richText={description} />}
      </Container>
    </Wrapper>
  </AppSection>
);

export default AppDownload;

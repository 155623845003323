import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import { navigate } from 'gatsby';
import BackArrow from '@components/svg-icons/back-arrow';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import {
  BackButton,
  StyledWrapper,
  Container,
  ImageContainer,
  StyledImage,
  TextContainer,
  Title,
  Subheading,
  DescriptionContainer,
  Description,
} from './bio-information.styled';

interface BioInfoProps {
  title?: string;
  subheading?: RichTextBlock[];
  description?: RichTextBlock[];
  image?: ImageProps;
}

const BioInformation = ({
  title,
  subheading = [],
  description = [],
  image,
}: BioInfoProps): JSX.Element => (
  <Section backgroundColor="light">
    <Wrapper>
      <Container>
        <BackButton type="button" onClick={() => navigate(-1)}>
          <BackArrow /> Back
        </BackButton>
        {title && image?.url && (
          <StyledWrapper>
            <ImageContainer>
              <StyledImage
                url={image?.url}
                alt={image?.alt}
                gatsbyImageData={image.gatsbyImageData}
                objectFit="contain"
              />
            </ImageContainer>
            <TextContainer>
              <Title tag="h2" type="title" fontSize="heading2" stringText={title} />
              {subheading?.length > 0 && subheading[0].text !== '' && (
                <Subheading type="subheading" richText={subheading} />
              )}
              {description?.length > 0 && description[0].text !== '' && (
                <DescriptionContainer>
                  <Description richText={description} />
                </DescriptionContainer>
              )}
            </TextContainer>
          </StyledWrapper>
        )}
      </Container>
    </Wrapper>
  </Section>
);

export default BioInformation;

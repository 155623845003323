import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Text from '@components/text';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { RichTextContainer } from '@components/paragraphs/paragraphs.shared.styled';
import CookieManagement from '@components/cookie-management';

const LegalRichText = styled(RichTextContainer)`
  ul,
  ol {
    margin-left: 1rem;
  }
`;

const LegalWrapper = styled(Wrapper)`
  max-width: 48rem;

  ${({ theme }) => css`
    width: 100%;
    padding: 8rem 0 0 0;
    position: relative;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 6rem 0 0 0 0;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 5rem 0 0 0;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 4rem 0 0 0;
    }
  `}
`;

interface LegalProps {
  edges: {
    node: {
      data: {
        title: {
          text: string;
        };
        meta_description?: {
          text: string;
        };
        meta_image?: {
          url: string;
          alt: string;
        };
        job_title?: {
          richText: RichTextBlock[];
        };
        photo: ImageProps;
        description: {
          richText: RichTextBlock[];
        };
        layout_type: 'dark' | 'light';
        show_cookie_management?: boolean;
        cookie_management_title: {
          text: string;
        };
        cookie_management_description: {
          richText: RichTextBlock[];
        };
        toggle_all_label: string;
      };
    };
  }[];
}

interface LegalInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    allPrismicLegal: LegalProps;
  };
}

const Legal = ({ data }: LegalInterface): JSX.Element | null => {
  if (!data) return null;

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    allPrismicLegal: { edges },
  } = data;

  const {
    title,
    meta_description,
    meta_image,
    description,
    layout_type,
    show_cookie_management,
    cookie_management_title,
    cookie_management_description,
    toggle_all_label,
  } = edges[0].node.data;

  return (
    <Layout layoutType={layout_type}>
      <SEO
        title={title.text}
        description={meta_description?.text}
        image={meta_image}
        faviconHref={favicon?.url || ''}
      />
      {show_cookie_management && (
        <CookieManagement
          title={cookie_management_title}
          description={cookie_management_description}
          toggleAllText={toggle_all_label}
        />
      )}
      <Section backgroundColor="white">
        <LegalWrapper>
          <Text type="title" fontSize="heading1" stringText={title?.text} margin="lg" />
          <LegalRichText>
            <Text type="description" richText={description.richText} />
          </LegalRichText>
        </LegalWrapper>
      </Section>
    </Layout>
  );
};

export default Legal;

export const query = graphql`
  query LegalQuery($uid: String) {
    prismicGeneralConfig {
      data {
        favicon {
          url
        }
      }
    }
    allPrismicLegal(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            title {
              text
            }
            meta_description {
              text
            }
            meta_image {
              url
              alt
            }
            photo {
              url
              fluid(maxWidth: 960) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            description {
              richText
            }
            layout_type
            show_cookie_management
            cookie_management_title {
              text
            }
            cookie_management_description {
              richText
            }
            toggle_all_label
          }
        }
      }
    }
  }
`;

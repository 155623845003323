import React, { useState } from 'react';
import { defaultTheme } from '@styles/theme.styled';
import Collapsible from 'react-collapsible';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import generateId from '@helpers/generate-id';
import getLinkConfig from '@helpers/get-link-config';
import Section from '@components/section';
import {
  TitleContainer,
  StyledItemTitle,
  OpenedSubheading,
  Container,
  Content,
  ImageContainer,
  StyledImage,
  StyledMobileImage,
  StyledLinkComponent,
  StyledItemLinkComponent,
  StyledWrapper,
} from './showcase.styled';

const idGenerator = generateId();

interface ItemProps {
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  image?: ImageProps;
  link?: {
    uid?: string;
  };
  link_label?: string;
}

export interface ShowcaseProps {
  title?: string;
  description?: RichTextBlock[];
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  items: ItemProps[];
  link?: {
    uid?: string;
    url?: string;
  };
  linkLabel?: string;
}

const Showcase = ({
  title,
  description,
  items,
  link,
  linkLabel,
  backgroundColor = 'white',
}: ShowcaseProps): JSX.Element => {
  const [image, setImage] = useState(items[0].image);
  const [opened, setOpened] = useState(0);
  const [animate, setAnimate] = useState(false);
  const linkConfig = getLinkConfig(link);

  return (
    <Section backgroundColor={backgroundColor}>
      <StyledWrapper>
        <TitleContainer>
          <Text tag="h2" type="title" stringText={title} fontSize="heading3" margin="sm" />
          {description && description?.length > 0 && (
            <Text type="subheading" richText={description} margin="xxs" />
          )}
        </TitleContainer>
        <Container>
          <Content>
            {items.map((item, i) => {
              const itemLinkConfig = getLinkConfig(item.link);
              const isOpen = opened === i;
              const itemId = idGenerator.next().value;

              return (
                <OpenedSubheading isOpen={isOpen} key={idGenerator.next().value}>
                  <Collapsible
                    trigger={
                      <StyledItemTitle
                        type="title"
                        tag="h3"
                        stringText={item.title?.text}
                        fontSize="subheading"
                      />
                    }
                    triggerElementProps={{
                      'aria-label': 'Open section',
                      tabIndex: 0,
                      role: 'button',
                      id: `showcase-trigger-${itemId}`,
                    }}
                    containerElementProps={{
                      id: `showcase-container-${itemId}`,
                    }}
                    open={i === opened}
                    transitionTime={250}
                    transitionCloseTime={0}
                    triggerWhenOpen={
                      <Text
                        type="title"
                        tag="h3"
                        margin="xs"
                        stringText={item.title?.text}
                        fontSize="subheading"
                      />
                    }
                    onOpening={() => {
                      setTimeout(() => setImage(item.image), 250);
                      setAnimate(true);
                      setOpened(i);
                    }}
                    easing="ease-in"
                  >
                    <StyledMobileImage
                      gatsbyImageData={image?.gatsbyImageData}
                      alt={image?.alt || ''}
                      url={image?.url}
                      objectFit="contain"
                    />

                    <Text
                      type="description"
                      fontSize="small"
                      richText={item.description?.richText}
                      margin="sm"
                    />
                    {!!itemLinkConfig.url && (
                      <StyledItemLinkComponent
                        url={itemLinkConfig.url}
                        isExternal={itemLinkConfig.isExternal}
                        title={item.link_label || 'Learn more'}
                      />
                    )}
                  </Collapsible>
                </OpenedSubheading>
              );
            })}
          </Content>
          <ImageContainer $animate={animate} onAnimationEnd={() => setAnimate(false)}>
            <StyledImage
              gatsbyImageData={image?.gatsbyImageData}
              alt={image?.alt || ''}
              url={image?.url}
              objectFit="contain"
            />
          </ImageContainer>{' '}
        </Container>
        {!!linkConfig.url && (
          <StyledLinkComponent
            isExternal={linkConfig.isExternal}
            url={linkConfig.url}
            title={linkLabel || 'Explore all products and features'}
          />
        )}
      </StyledWrapper>
    </Section>
  );
};

export default Showcase;

import styled from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';

export const Container = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 29.13rem;
  width: 100%;
  ${({ theme }) => `
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      margin: 0 auto;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => `
    margin-bottom:  0.5rem;
    font-size: ${theme.fontSize.heading6.mobile};
    color: ${theme.colors.black};
  `}
`;

export const StyledDescription = styled(Text)`
  ${({ theme }) => `
    font-size: 1rem;
    line-height: ${theme.lineHeights.sm};
    color: ${theme.colors.muted};
  `}
`;

export const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 3.25rem;
  height: 3.25rem;
`;

export const StyledImage = styled(Image)`
  width: 2.5rem;
  height: 2.5rem;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    max-width: 32rem;
    height: 100%;
    margin-left: 1rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
    max-width: 100%;
    }
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    ${generateScales('font-size', '2rem', '3rem', theme)};
    line-height: ${theme.lineHeights.xl};
    text-align: center;
    color: ${theme.colors.black};
    padding-bottom: ${theme.padding.lg};
  `}
`;

export const StyledSubheading = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.black};
    font-size: ${theme.fontSize.heading4.desktop};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center;
      font-size: ${theme.fontSize.heading5.desktop};
      line-height: 4rem;
    }
  `}
`;

import styled, { css } from 'styled-components';
import Input from '@components/input';
import Button from '@components/button';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1.75rem;
`;

export const FormRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      row-gap: 1rem;
    }
  `}
`;

export const StyledInput = styled(Input)<{ isInvalid: boolean }>`
  ${({ theme, isInvalid }) => css`
    width: 100%;
    padding: 0 0.625rem;
    border-radius: 0.125rem;
    border: 1px solid ${isInvalid ? theme.colors.tertiary : theme.colors.light};
    color: ${theme.colors.primary};
    max-height: 2.25rem;
    font-size: ${theme.fontSize.small.desktop};

    :focus {
      border-color: ${theme.colors.secondary};

      ::-webkit-input-placeholder {
        color: ${theme.colors.secondary};
      }
    }

    ::-webkit-input-placeholder {
      color: ${isInvalid ? theme.colors.tertiary : theme.colors.secondary};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-height: 2.5rem;
    }
  `}
`;

export const HelperText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.additional};
    color: ${theme.colors.tertiary};
    font-size: ${theme.fontSize.extraSmall.desktop};
    margin-top: 0.375rem;
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.secondary};
    border-color: ${theme.colors.secondary};
    color: ${theme.colors.white};

    :hover {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const ConfirmationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    row-gap: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${theme.backgroundColors.white};
    padding: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 2rem 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 2rem 1rem;
    }
  `}
`;

export const NewsletterContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 30rem;
    align-self: center;
    margin: 0 auto;
    ${generateScales('margin-top', '3rem', '6rem', theme)}
    text-align: center;
  `}
`;

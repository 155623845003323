import styled, { css } from 'styled-components';
import Image from '@components/image';
import Text from '@components/text';
import VectorIcon from '@components/svg-icons/vector';
import { generateScales } from '@helpers/generate-media-queries';

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    ${generateScales('margin-bottom', '2rem', '4rem', theme)}
    max-width: 40rem;
    width: 100%;
    text-align: center;

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('margin-bottom', '4rem', '7rem', theme)}
    position: relative;
  `}
`;

export const OpenedContent = styled.div`
  padding: 1.5rem 0rem;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  display: flex;
  font-weight: 700;
  line-height: 1.25rem;
  cursor: pointer;
  ${({ theme }) => css`
    column-gap: 4rem;
    @media (max-width: ${theme.breakpoints.md}) {
      column-gap: 2rem;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      column-gap: 1.5rem;
    }

    ${generateScales(
      'font-size',
      theme.fontSize.small.mobile,
      theme.fontSize.subheading.desktop,
      theme
    )}
    color: ${theme.colors.primary};
  `}
`;

export const OpenedSubheading = styled.div`
  ${({ theme }) => css`
    padding: 0 0rem 1.5rem 3.75rem;
    max-width: 40rem;
    font-size: 1rem;
    color: ${theme.colors.primary};

    @media (max-width: ${theme.breakpoints.md}) {
      padding-left: 0.5rem;
    }
  `}
`;

export const AccordionItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0rem;
    font-weight: 500;
    cursor: pointer;
    align-items: flex-start;
    column-gap: 4rem;
    color: ${theme.colors.primary};
    ${generateScales(
      'font-size',
      theme.fontSize.body.mobile,
      theme.fontSize.subheading.desktop,
      theme
    )}

    @media (max-width: ${theme.breakpoints.md}) {
      column-gap: 2rem;
      padding: 1.25rem 0rem;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      column-gap: 1.5rem;
      padding: 1.25rem 0rem;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    width: 100%;
    max-width: 56rem;
    margin: ${theme.padding.sm} auto;
    border-top: 1px solid #e699ff;
  `}
`;

export const ListItem = styled.li`
  border-bottom: 1px solid #e699ff;
`;

export const StyledVector = styled(VectorIcon)<{ isRotated: boolean }>`
  ${({ isRotated }) =>
    isRotated &&
    css`
      transform: rotate(180deg);
    `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    height: 2.75rem;
    width: 2.75rem;
    min-height: 2.75rem;
    min-width: 2.75rem;
    align-self: flex-start;

    @media (max-width: ${theme.breakpoints.sm}) {
      width: 2.5rem;
      height: 2.5rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
    }

    img,
    div,
    picture {
      object-position: center center;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 100%;
`;

export const TextAndIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 2.75rem;
    align-self: flex-start;

    @media (max-width: ${theme.breakpoints.sm}) {
      height: 3rem;
    }

    column-gap: 0.375rem;
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    color: ${theme.colors.secondary};
    font-weight: 400;
    margin: 0;
    width: fit-content;
    text-wrap: nowrap;

    @media (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const StyledText = styled(Text)`
  width: 100%;
  margin: 0;
  max-width: 40rem;
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

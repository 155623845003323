import styled, { css } from 'styled-components';
import LinkComponent from '@components/link';
import { headerHeight } from '@components/header/header.styled';

export const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: ${headerHeight};
`;

export const LinkButton = styled(LinkComponent)`
  ${({ theme }) => css`
    margin-top: ${theme.padding.md};

    &:hover {
      background-color: ${theme.colors.secondary};
      color: ${theme.colors.white};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: ${theme.padding.sm};
    }
  `}
`;

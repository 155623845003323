import styled, { css } from 'styled-components';
import Arrow from '@components/svg-icons/arrow';
import { generateScales } from '@helpers/generate-media-queries';

export const DropdownContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    border: 1px solid ${theme.colors.light};
    height: 2.75rem;
    border-radius: 0.125rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }
  `}
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 0.5rem;
  transform: rotate(90deg);
  height: auto;
  width: 1rem;
`;

export const Text = styled.span`
  ${({ theme }) => `
    width: 100%;
    color: ${theme.colors.secondary};
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.sm};
    font-size: 1rem;
    text-decoration: none;
    border: none;
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => `
    list-style: none;
    padding: 0.75rem 0.375rem;
    border-radius: 0.125rem;
    cursor: pointer;

    :not(:last-of-type) {
      padding-bottom: 0;
    }

    :hover {
      padding: 0.75rem 0.375rem;
      background-color: ${theme.colors.accent};
      color: ${theme.colors.dark};
    }

    :hover + li {
      padding-top: 0;
    }

    :hover ${Text} {
      color: ${theme.colors.primary};
    }
  `}
`;

export const DropDownList = styled.ul`
  ${({ theme }) => `
    margin-top: 0.8rem;
    padding: 0.375rem; 
    border: 1px solid ${theme.colors.light};
    background-color: ${theme.colors.white};
    box-sizing: border-box;
    color: ${theme.colors.dark};
    font-weight: 500;
    border-radius: 0.125rem;
  `}
`;

export const DropDownListContainer = styled.div``;

interface ButtonProps {
  isVisible?: boolean;
  isSet: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, isSet, isVisible }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return css`
      text-decoration: none;
      width: 100%;
      text-align: left;
      line-height: 2.5rem;
      padding-left: 0.5rem;
      font-family: ${theme.fontFamily.text};
      font-size: ${theme.fontSize.body.desktop};
      cursor: pointer;
      width: 100%;
      color: ${isSet ? theme.colors.primary : theme.colors.secondary};
      background-color: ${theme.colors.white};
      border: none;
      ${generateScales('height', mobile.height, desktop.height, theme)}
      ${generateScales(
        'font-size',
        theme.fontSize.body.mobile,
        theme.fontSize.body.desktop,
        theme
      )};

      ${isVisible &&
      css`
        background-color: ${theme.colors.white};
        color: ${isSet ? theme.colors.primary : theme.colors.secondary};
      `};
    `;
  }}
`;

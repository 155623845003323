import React from 'react';
import { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import {
  ImageContainer,
  StyledTitle,
  Description,
  Container,
  StyledSection,
  StyledImage,
  StyledGrid,
} from './feature-case.styled';

const FeatureCaseIdGenerator = generateId();

export interface FeatureCaseProps {
  items: FeatureCasePropsItem[];
  itemsPerRow?: number;
}

export interface FeatureCasePropsItem {
  image?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    text: string;
  };
}

const FeatureCase = ({ items, itemsPerRow = 3 }: FeatureCaseProps): JSX.Element => {
  return (
    <StyledSection backgroundColor="white">
      <Wrapper>
        <StyledGrid itemsPerRow={itemsPerRow}>
          {items?.map(({ image, title, description }) => {
            return (
              <Container key={FeatureCaseIdGenerator.next().value}>
                {image?.url && (
                  <ImageContainer>
                    <StyledImage url={image?.url} alt={image?.alt} />
                  </ImageContainer>
                )}
                {title && (
                  <StyledTitle
                    tag="h3"
                    fontColor="dark"
                    type="title"
                    fontSize="heading5"
                    stringText={title.text}
                  />
                )}
                {description?.text && <Description>{description?.text}</Description>}
              </Container>
            );
          })}
        </StyledGrid>
      </Wrapper>
    </StyledSection>
  );
};

export default FeatureCase;

import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';
import { wrapperPadding } from '@components/wrapper.styled';
import { underlineTransition } from '@components/link/link.styled';

export const BackButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    background: none;
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSize.body.desktop};
    line-height: ${theme.lineHeights.lg}rem;
    color: ${theme.colors.secondary};
    cursor: pointer;
    column-gap: 0.375rem;

    ${underlineTransition}

    svg {
      stroke: ${theme.colors.secondary};
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: flex-start;
    column-gap: 2rem;
    padding-top: 2.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 2rem;
    }
  `}
`;

export const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 3rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      column-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      row-gap: 2rem;
    }
  `}
`;

export const ImageContainer = styled.div`
  width: 36rem;
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin: 0 auto;
      width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: calc(100% + (${wrapperPadding} * 2));
      margin-left: -${wrapperPadding};
      margin-right: -${wrapperPadding};
    }
  `};
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
    }
  `};
`;

export const TextContainer = styled.div`
  width: 100%;

  ${({ theme }) => `
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  `};
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.secondary};
    margin: 0 0 ${theme.spacing.xs} 0;
  `}
`;

export const Subheading = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.muted};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.desktop, theme)}
    font-weight: 500;
    line-height: ${theme.lineHeights.lg};
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 29rem;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  `}
`;

export const Description = styled(Text)`
  ${({ theme }) => css`
    justify-content: space-between;
    font-family: ${theme.fontFamily.text};
    color: ${theme.colors.dark};

    p {
      margin-top: 1.5rem;
      line-height: ${theme.lineHeights.xl};
      ${generateScales('font-size', '0.875rem', '1.125rem', theme)}
      font-family: ${theme.fontFamily.additional};
      color: ${theme.colors.dark};
    }
  `}
`;

import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import LinkComponent from '@components/link';
import getLinkConfig from '@helpers/get-link-config';
import { Container, StyledImage, Content, StyledSubheading, Title } from './image-box.styled';

interface ImageBoxProps {
  title?: string;
  description?: RichTextBlock[];
  image?: ImageProps;
  link?: {
    uid?: string;
    url?: string;
  };
  link_label?: string;
}

const ImageBox = ({
  title,
  description = [],
  image,
  link,
  link_label,
}: ImageBoxProps): JSX.Element => {
  const isSubheadingExisting: boolean = description?.length > 0 && description[0].text !== '';
  const linkConfig = getLinkConfig(link);
  if (!title && !image && !isSubheadingExisting && !linkConfig.url) {
    return <></>;
  }

  return (
    <Container>
      <Content>
        {title && <Title type="title" tag="h3" stringText={title} />}
        {isSubheadingExisting && <StyledSubheading type="description" richText={description} />}
        {!!linkConfig.url && (
          <LinkComponent
            type="text"
            url={linkConfig.url}
            isExternal={linkConfig.isExternal}
            title={link_label || 'Explore all products and features '}
          />
        )}
      </Content>
      {(image?.gatsbyImageData || image?.url) && (
        <StyledImage url={image.url} alt={image.alt} gatsbyImageData={image.gatsbyImageData} />
      )}
    </Container>
  );
};

export default ImageBox;

import styled, { css } from 'styled-components';
import Grid from '@components/grid';
import Image from '@components/image';
import Text from '@components/text';
import Section from '@components/section';
import { defaultTheme } from '@styles/theme.styled';

export const TextContent = styled.div`
  max-width: 40rem;
  align-self: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;

export const BlockDescription = styled(Text)``;

export const BlockTitle = styled(Text)<{ marginBottom?: string }>`
  margin-bottom: 0;
`;

export const SectionTitle = styled(Text)``;

export const SectionDescription = styled(Text)``;

export const BlockImage = styled(Image)`
  width: auto;
  height: auto;

  div,
  img,
  picture {
    object-position: center center;
  }
`;

export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    margin-top: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin-top: 3rem;
      grid-template-columns: 1fr;
    }
  `}
`;

export const Block = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: row;
      justify-items: center;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
    }
  `}
`;

interface SectionProps {
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

export const PartnerSection = styled(Section)<SectionProps>`
  ${({ theme, backgroundColor }) => css`
    ${backgroundColor === ('dark' || 'primary') &&
    css`
      ${SectionDescription} {
        color: ${theme.colors.brand};
      }

      ${SectionTitle},
      ${BlockDescription},
      ${BlockTitle} {
        color: ${theme.colors.white};
      }
    `}
  `}
`;

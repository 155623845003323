import styled from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledDescription = styled(Text)`
  margin-top: 0;
`;

export const StyledTitle = styled(Text)`
  margin-top: 1.25rem;
  display: block;
`;

export const Description = styled.p`
  ${({ theme }) => `
    margin: 1rem 1.25rem;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.base};
  `}
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 3rem;
  margin: 0 auto;
  max-width: 3rem;
  overflow: hidden;
  border-radius: 100%;
  width: 100%;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => `
    ${generateScales(
      'font-size',
      theme.fontSize.heading2.mobile,
      theme.fontSize.heading2.desktop,
      theme
    )};
  text-align: center;
  color: ${theme.colors.black};
  margin: 0 0 4rem 0;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => `
    text-align: center;
    background-color: ${theme.colors.lightGray};
    padding: 3rem 2rem;
    border-radius: ${theme.borderRadius.normal};
  `}
`;

import React from 'react';

interface CaretProps {
  className?: string;
  layoutType?: 'dark' | 'light';
}

const Caret = ({ className = '', layoutType }: CaretProps): JSX.Element => {
  const fill = layoutType === 'dark' ? 'white' : '#3D1152';
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.59456 5.4382C3.79413 5.71473 4.20587 5.71473 4.40544 5.4382L7.75812 0.792603C7.99677 0.461921 7.76048 0 7.35267 0H0.647327C0.239523 0 0.00323534 0.461921 0.241885 0.792603L3.59456 5.4382Z"
        fill={fill}
      />
    </svg>
  );
};

export default Caret;

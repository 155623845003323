import styled, { css } from 'styled-components';
import Wrapper from '@components/wrapper.styled';

export const LegalWrapper = styled(Wrapper)`
  max-width: 48rem;
  margin-top: 2.5rem;
`;

export const CookieManagementContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.normal};
    padding: 3rem 2rem 2rem 2rem;
  `}
`;

export const Toggle = styled.span`
  ${({ theme }) => css`
    display: flex;
    width: 3rem;
    height: 1rem;
    position: relative;
    border-radius: 0.5rem;
    background-color: ${theme.backgroundColors.dark};
    transition: background-color 0.2s;
    flex-direction: column;
    filter: grayscale(1);

    &::before {
      display: block;
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.75rem;
      background-color: ${theme.colors.secondary};
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  `}
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${({ theme }) => css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:checked + ${Toggle} {
      filter: grayscale(0);
    }

    &:checked + ${Toggle}::before {
      left: unset;
      right: 0;
      background-color: ${theme.colors.secondary};
    }

    /* &:focus + ${Toggle}::before {
      outline: ${theme.colors.secondary} solid 2px;
      outline-offset: 2px;
    } */
  `}
`;

export const ToggleLabel = styled.label`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem;
    background-color: ${theme.backgroundColors.light};
    border-radius: 0.5rem;
    margin-top: 2rem;
    cursor: pointer;
    font-size: ${theme.fontSize.body};
    color: ${theme.colors.darkPurple};
    font-weight: bold;
  `}
`;

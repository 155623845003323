import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import getLinkConfig from '@helpers/get-link-config';
import generateId from '@helpers/generate-id';
import Section from '@components/section';
import {
  StyledTitle,
  ImageContainer,
  Container,
  Block,
  CopyBlock,
  TextContent,
  StyledImage,
  StyledLink,
  Badge,
} from './touts-in-row.styled';

const toutsInRowIdGenerator = generateId();

interface ToutsInRowItemProps {
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  image?: ImageProps;
  link?: {
    uid?: string;
    url?: string;
  };
  link_label?: string;
  badge?: string;
}

interface ToutsInRowProps {
  title?: string;
  items: ToutsInRowItemProps[];
}

const ToutsInRow = ({ title, items }: ToutsInRowProps): JSX.Element => (
  <Section backgroundColor="white">
    <Wrapper>
      {!!title && <StyledTitle type="title" tag="h2" fontSize="heading2" stringText={title} />}
      <Container>
        {items.map((item) => {
          const linkConfig = getLinkConfig(item.link);

          return (
            <Block key={toutsInRowIdGenerator.next().value}>
              {!!item.badge && <Badge>{item.badge}</Badge>}
              {(item.image?.gatsbyImageData || item.image?.url) && (
                <ImageContainer>
                  <StyledImage
                    gatsbyImageData={item.image.gatsbyImageData}
                    url={item.image.url}
                    alt={item.image.alt || ''}
                    objectFit="contain"
                  />
                </ImageContainer>
              )}
              <TextContent>
                <CopyBlock>
                  {!!item.title?.text && (
                    <Text
                      type="title"
                      tag="h3"
                      stringText={item.title?.text}
                      fontSize="heading5"
                      margin="xs"
                    />
                  )}
                  {!!item.description?.richText && (
                    <Text
                      type="description"
                      fontColor="primary"
                      richText={item.description?.richText}
                      margin="md"
                    />
                  )}
                </CopyBlock>
                {!!linkConfig.url && (
                  <StyledLink
                    title={item.link_label || 'Explore'}
                    url={linkConfig.url}
                    isExternal={linkConfig.isExternal}
                  />
                )}
              </TextContent>
            </Block>
          );
        })}
      </Container>
    </Wrapper>
  </Section>
);

export default ToutsInRow;

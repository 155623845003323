import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import generateId from '@helpers/generate-id';
import BioInformation from '@components/bio-information';
import renderModule from '@components/module';
import { Container } from '../pages';

const bioIdGenerator = generateId();

interface BioPageProps {
  edges: {
    node: {
      data: {
        name: {
          text: string;
        };
        meta_description?: {
          text: string;
        };
        meta_image?: {
          url: string;
          alt: string;
        };
        job_title?: {
          richText: RichTextBlock[];
        };
        photo: ImageProps;
        description: {
          richText: RichTextBlock[];
        };
      };
    };
  }[];
}

interface BioInterface {
  data: {
    currentPage: BioPageProps;
    allPrismicBioPage: BioPageProps;

    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
        logo?: {
          alt: string;
          url: string;
        };
      };
    };
    allPrismicPage: {
      nodes: {
        last_publication_date: string;
        data: {
          layout_type: 'dark' | 'light';
          body: [];
        };
      }[];
    };
  };
}

const Bio = ({ data }: BioInterface): JSX.Element | null => {
  if (!data) return null;

  const {
    allPrismicPage,
    prismicGeneralConfig: {
      data: { favicon },
    },
    currentPage: { edges },
  } = data;

  const { name, meta_description, meta_image, job_title, photo, description } = edges[0].node.data;

  return (
    <Layout layoutType={allPrismicPage.nodes[0].data.layout_type}>
      <SEO
        title={name.text}
        description={meta_description?.text}
        image={meta_image}
        faviconHref={favicon?.url || ''}
      />
      <Container>
        <BioInformation
          title={name?.text}
          subheading={job_title?.richText}
          description={description.richText}
          image={photo}
        />
        {allPrismicPage.nodes[0].data.body.map(
          (module) =>
            module && (
              <React.Fragment key={bioIdGenerator.next().value}>
                {renderModule(module, allPrismicPage.nodes[0].last_publication_date)}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default Bio;

export const query = graphql`
  query AllBioQuery($uid: String) {
    prismicGeneralConfig {
      data {
        favicon {
          url
        }
      }
    }
    allPrismicPage(filter: { uid: { eq: "about" } }) {
      nodes {
        data {
          layout_type
          body {
            ... on PrismicPageDataBodyMeetTheLeaders {
              id
              slice_type
              primary {
                items_in_row
                title {
                  text
                }
                positions_title {
                  text
                }
              }
              items {
                enable_bio_page
                bio {
                  document {
                    ... on PrismicBioPage {
                      id
                      data {
                        photo {
                          url
                          gatsbyImageData
                          alt
                        }
                        name {
                          text
                        }
                        job_title {
                          richText
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyTout {
              slice_type
              primary {
                layout_type
                title {
                  text
                }
                subheading {
                  richText
                }
                image {
                  url
                  alt
                  gatsbyImageData
                }
                link_label
                link {
                  url
                  uid
                }
              }
            }
          }
        }
      }
    }
    currentPage: allPrismicBioPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            name {
              text
            }
            meta_description {
              text
            }
            meta_image {
              url
              alt
            }
            job_title {
              richText
            }
            photo {
              url
              fluid(maxWidth: 960) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            description {
              richText
            }
          }
        }
      }
    }
    allPrismicBioPage(sort: { fields: first_publication_date, order: ASC }) {
      edges {
        node {
          data {
            name {
              text
            }
            job_title {
              richText
            }
            photo {
              url
              fluid(maxWidth: 960) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
          }
        }
      }
    }
  }
`;

import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Text from '@components/text';
import { TextSectionProps } from './index';

const getMargin = (align: string): string => {
  switch (align) {
    case 'center':
      return '0 auto';
    case 'right':
      return '0 0 0 auto';
    case 'left':
      return '0 auto 0 0';
    default:
      return '0 auto';
  }
};

export const StyledTitle = styled(Text)`
  ${({ theme }) => `
    ${generateScales('margin-bottom', '0.5rem', '1.25rem', theme)}
  `}
`;

export const Container = styled.div<TextSectionProps>`
  ${({ theme, align = 'center', backgroundColor }) => css`
    width: 100%;
    max-width: 48rem;
    margin: 0 0 0 auto;
    text-align: ${align};
    margin: ${getMargin(align)};
    ${(backgroundColor === 'primary' || backgroundColor === 'dark') &&
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${theme.colors.white};
      }

      p {
        color: ${theme.colors.brand};
      }
    `}
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;

import styled from 'styled-components';

export const Container = styled.div<{ align?: string }>`
  ${({ align = 'center' }) => `
    text-align: ${align.toLowerCase()};
  `}
`;

export const Date = styled.div`
  ${({ theme }) => `
    margin-top: 0.5rem;
    color: ${theme.colors.black};
    font-size: ${theme.fontSize.heading6.desktop};
    line-height: 1;
  `}
`;

import React, { useState } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Image, { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import generateId from '@helpers/generate-id';
import {
  StyledHead,
  StyledGrid,
  HeadContent,
  ImageContainer,
  ButtonsContainer,
  StyledButton,
  Block,
  BlockDescription,
  IconContainer,
  StyledImage,
  TextBlock,
  TabContent,
} from './experiences.styled';

const experiencesIdGenerator = generateId();

interface ExperiencesItemType {
  icon?: ImageProps;
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
}

export interface ContentType {
  document: {
    data: {
      title: {
        text: string;
      };
      image: ImageProps;
      items: ExperiencesItemType[];
    };
  };
}

interface ExperiencesProps {
  title?: string;
  description?: RichTextBlock[];
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  content?: (ContentType | undefined)[];
}

const Experiences = ({
  title,
  description,
  backgroundColor = 'transparent',
  content = [],
}: ExperiencesProps): JSX.Element => {
  const [viewContent, setViewContent] = useState(content[0]?.document.data);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  return (
    <Section backgroundColor={backgroundColor}>
      <Wrapper>
        <StyledHead>
          <HeadContent>
            {!!title && (
              <Text tag="h2" type="title" stringText={title} fontSize="heading2" margin="sm" />
            )}
            {!!description?.length && (
              <Text type="subheading" richText={description} fontSize="subheading" />
            )}
            <TabContent>
              <ButtonsContainer>
                {content?.map((item, index) => (
                  <StyledButton
                    type="button"
                    onClick={() => {
                      if (index !== activeIndex) {
                        setAnimate(true);
                        setActiveIndex(index);
                      }
                      setTimeout(() => {
                        setViewContent(item?.document.data);
                      }, 250);
                    }}
                    isActive={viewContent === item?.document.data}
                    key={experiencesIdGenerator.next().value}
                  >
                    {item?.document.data.title.text}
                  </StyledButton>
                ))}
              </ButtonsContainer>
              <ImageContainer animate={animate} onAnimationEnd={() => setAnimate(false)}>
                {!!(viewContent?.image.gatsbyImageData || viewContent?.image.url) && (
                  <StyledImage
                    gatsbyImageData={viewContent?.image.gatsbyImageData}
                    url={viewContent?.image.url}
                    alt={viewContent?.image.alt || ''}
                    objectFit="contain"
                  />
                )}
              </ImageContainer>
            </TabContent>
          </HeadContent>
          <ImageContainer animate={animate} onAnimationEnd={() => setAnimate(false)}>
            {!!(viewContent?.image.gatsbyImageData || viewContent?.image.url) && (
              <StyledImage
                gatsbyImageData={viewContent?.image.gatsbyImageData}
                url={viewContent?.image.url}
                alt={viewContent?.image.alt || ''}
                objectFit="contain"
              />
            )}
          </ImageContainer>
        </StyledHead>
        <StyledGrid>
          {viewContent?.items.map((item) => (
            <Block key={experiencesIdGenerator.next().value}>
              <IconContainer>
                {!!(item.icon?.url || item.icon?.gatsbyImageData) && (
                  <Image
                    gatsbyImageData={item.icon.gatsbyImageData}
                    url={item.icon.url}
                    alt={item.icon.alt || ''}
                  />
                )}
              </IconContainer>
              <TextBlock>
                {!!item.title && (
                  <Text
                    tag="h3"
                    type="title"
                    stringText={item.title.text}
                    fontSize="heading6"
                    margin="xs"
                  />
                )}
                {!!item.description?.richText.length && (
                  <BlockDescription
                    type="description"
                    richText={item.description.richText}
                    fontSize="body"
                  />
                )}
              </TextBlock>
            </Block>
          ))}
        </StyledGrid>
      </Wrapper>
    </Section>
  );
};

export default Experiences;

import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RichTextBlock } from 'prismic-reactjs';
import Section from '@components/section';
import generateId from '@helpers/generate-id';
import Text from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import { ImageProps } from '@components/image';
import { SliderWrapper, StyledSlider, HeadingContainer, PartnerImage } from './partners.styled';

const partnersIdGenerator = generateId();
const imageIdGenerator = generateId();

interface PartnersItemType {
  image?: ImageProps;
}

interface PartnersProps {
  title?: string;
  description?: RichTextBlock[];
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  slidesToShow?: number;
  numberInRows?: number;
  items?: PartnersItemType[];
}

const Partners = ({
  title,
  description,
  backgroundColor = 'transparent',
  slidesToShow = 4,
  numberInRows = 1,
  items = [],
}: PartnersProps): JSX.Element => {
  if (items?.length === 0) {
    return <></>;
  }
  // const index = Math.round(items.length / numberInRows);
  // const rows = [items.slice(0, index), items.slice(index)];
  const rows = () => {
    const rowArray = [];
    for (let index = 0; index < numberInRows; index += 1) {
      rowArray.push(items);
    }
    return rowArray;
  };

  return (
    <Section backgroundColor={backgroundColor}>
      <HeadingContainer>
        {!!title && <Text type="title" fontSize="heading3" tag="h2" stringText={title} />}
        {!!description && <Text type="description" fontSize="subheading" richText={description} />}
      </HeadingContainer>
      <SliderWrapper>
        {rows().map((row, index) => {
          const settings = {
            infinite: true,
            slidesToShow,
            rtl: !!(index % 2),
            slidesToScroll: 1,
            autoplay: true,
            speed: 20000,
            autoplaySpeed: 0,
            cssEase: 'linear',
            pauseOnHover: false,
            arrows: false,
            responsive: [
              {
                breakpoint: +defaultTheme.breakpoints.md.slice(0, -2),
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: +defaultTheme.breakpoints.sm.slice(0, -2),
                settings: {
                  slidesToShow: 2,
                },
              },
            ],
          };
          const sliderId = partnersIdGenerator.next().value;

          return (
            <StyledSlider {...settings} key={sliderId} className={`slider-${sliderId}`}>
              {row?.map(({ image }) => (
                <PartnerImage
                  gatsbyImageData={image?.gatsbyImageData}
                  url={image?.url}
                  alt={image?.alt || ''}
                  key={imageIdGenerator.next().value}
                  objectFit="contain"
                />
              ))}
            </StyledSlider>
          );
        })}
      </SliderWrapper>
    </Section>
  );
};

export default Partners;

import React from 'react';
import Image, { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import { RichTextBlock } from 'prismic-reactjs';
import getLinkConfig from '@helpers/get-link-config';
import Section from '@components/section';
import { defaultTheme } from '@styles/theme.styled';
import Text from '@components/text';
import {
  StyledGrid,
  ImageContainer,
  TextContent,
  Title,
  StyledSubheading,
  Container,
  StyledLinkComponent,
} from './card-touts.styled';

const CardToutsIdGenerator = generateId();

export interface CardToutsProps {
  title?: string;
  subheading?: RichTextBlock[];
  items: CardToutsPropsItem[];
  itemsPerRow?: number;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

export interface CardToutsPropsItem {
  image?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link_label?: string;
  link?: {
    url?: string;
    uid?: string;
  };
}

const CardTouts = ({
  items,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  itemsPerRow = 2,
  title,
  subheading,
}: CardToutsProps): JSX.Element => {
  if (!title && !subheading) {
    return <></>;
  }

  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        {title && (
          <Title type="title" tag="h2" fontSize="heading3" stringText={title} margin="lg" />
        )}
        {subheading && <StyledSubheading type="subheading" richText={subheading} />}
        <StyledGrid itemsPerRow={itemsPerRow}>
          {items.map((item) => {
            const linkConfig = getLinkConfig(item.link);
            return (
              <Container key={CardToutsIdGenerator.next().value}>
                {item.image?.url && (
                  <ImageContainer>
                    <Image
                      gatsbyImageData={item.image?.gatsbyImageData}
                      url={item.image?.url}
                      alt={item.image?.alt || ''}
                      objectFit="contain"
                    />
                  </ImageContainer>
                )}
                <TextContent>
                  {item.title?.text && (
                    <Text tag="h3" type="title" fontSize="heading6" stringText={item.title?.text} />
                  )}
                  {!!item.description?.richText.length && (
                    <Text
                      type="description"
                      fontSize="subheading"
                      fontColor="dark"
                      richText={item.description?.richText}
                    />
                  )}
                  {!!linkConfig.url && (
                    <StyledLinkComponent
                      title={item.link_label || 'Learn more'}
                      url={linkConfig.url}
                      fontColor="secondary"
                      isExternal={linkConfig.isExternal}
                    />
                  )}
                </TextContent>
              </Container>
            );
          })}
        </StyledGrid>
      </Wrapper>
    </Section>
  );
};

export default CardTouts;

import React, { MouseEvent } from 'react';
import { defaultTheme } from '@styles/theme.styled';
import {
  StyledSmallButton,
  StyledMediumButton,
  StyledLargeButton,
  StyledArrow,
} from './button.styled';

export interface ButtonProps {
  outlined?: boolean;
  variant?: keyof typeof defaultTheme.buttonVariants;
  size?: 'small' | 'medium';
  type?: 'button' | 'submit' | 'reset';
  label?: string;
  disabled?: boolean;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  isIconVisible?: boolean;
  className?: string;
}

const Button = ({
  outlined = false,
  variant,
  size,
  type,
  label,
  onClick,
  disabled = false,
  isIconVisible,
  className,
}: ButtonProps): JSX.Element | null => {
  switch (size) {
    case 'small':
      return (
        <StyledSmallButton
          outlined={outlined}
          variant={variant}
          type={type}
          onClick={onClick}
          className={className}
          disabled={disabled}
        >
          {label}
        </StyledSmallButton>
      );
    case 'medium':
      return (
        <StyledMediumButton
          outlined={outlined}
          variant={variant}
          type={type}
          onClick={onClick}
          disabled={disabled}
          className={className}
        >
          {label}
        </StyledMediumButton>
      );
    default:
      return (
        <StyledLargeButton
          outlined={outlined}
          variant={variant}
          type={type}
          onClick={onClick}
          disabled={disabled}
          className={className}
        >
          {label}
          {isIconVisible && <StyledArrow />}
        </StyledLargeButton>
      );
  }
};

export default Button;
